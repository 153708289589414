import React from "react";
import "./SurgirSectionOperation.scss";
import { Container } from "react-bootstrap";

interface SurgirSectionOperationProps {
  image: string,
  steppers: {
    title: string,
    description: string
  }[],
  ariaLabel: string

}

const SurgirSectionOperation: React.FC<SurgirSectionOperationProps> = ({
  image,
  steppers,
  ariaLabel
}) => {

  return (

    <div className="section-operation">
      
      <div className="title-section">¿Cómo funciona?</div>
      
      <Container>
        <div className="container-section-operation">
        <div 
          className="section-operation--hero"
          style={{ backgroundImage: `url("images/section-operation/${image}.svg")` }}
          aria-label={ariaLabel}
        >

        </div>
        <div className="section-operation--steppers">
        <ol>
                {
                  steppers.map((item, index) => {
                    return (
                      <li key={item.title}
                        className={"section-operation--steppers__item"}
                        data-content={index + 1}
                      >
                        <div className="section-operation--steppers__content">
                          <h5 className="section-operation--steppers__content__title">{item.title}</h5>
                          <h6 className="section-operation--steppers__content__description">{item.description}</h6>
                        </div>
                      </li>
                    )
                  })
                }
              </ol>
        </div>
      </div>
      </Container>
      
    </div>


  )
}

export default SurgirSectionOperation