
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SurgirButton from "../../components/ui/SurgirButton/SurgirButton";
import SurgirDownloadIcon from "../../components/ui/SurgirDownloadIcon/SurgirDownloadIcon";
import { useNavigate } from "react-router-dom";
import { PLANS, TABLE_PLANS, OPERATIONS, COVERAGE, TYPESINSURANCE } from "./CreditInsurancePercentage.fixture";
import "./CreditInsurancePercentage.scss";
import {
  Container,
  Table,
} from "react-bootstrap";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "../../assets/swiper/swiper.css";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SurgirBanner from "components/ui/SurgirBanner/SurgirBanner";

import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import SurgirAccordion from "components/ui/SurgirAccordion/SurgirAccordion";
import AccordionIcon from "./components/SurgirAcordeon/AccordionIcon";
import SurgirSectionOperation from "components/ui/SurgirSectionOperation/SurgirSectionOperation";
import SurgirCardsProductCredit from "components/ui/SurgirCardsProductCredit/SurgirCardsProductCredit";
import SurgirSectionContact from "components/ui/SurgirSectionContact/SurgirSectionContact";

const titleSectionPlans = "Elige tu seguro ideal";

const CreditInsurancePercentage: React.FC = () => {

  const navigate = useNavigate();

  const requestCredit = () => {
    navigate("/contactanos");
    window.scrollTo(0, 0);
  };

  const [tablePlanVisible, setTablePlanVisible] = useState(false);
  const [showCardsCoverage, setShowCardsCoverage] = useState(6);
  const [topInfoSection, setInfoSection] = useState(0);
  const [keyTabInfo, setKeyTabInfo] = useState("Requisitos");


  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => {
        initTopSectionInfo();
        setMatches(media.matches)
      };
      window.addEventListener('resize', listener);
      initTopSectionInfo();
      return () => window.removeEventListener("resize", listener)
    }, [matches, query]);

    return matches;
  }

  const mobile = useMediaQuery('(max-width: 575px)');
  const isTabletSm = useMediaQuery('(min-width: 576px) and (max-width: 767px)');

  const isAcordionLg = useMediaQuery('(min-width: 768px)');
  const mobiletbPlans = useMediaQuery('(max-width: 767px)');


  const styleCardsPlans = (index: any) => {

    if (index === 0) {
      return 'section-sure__cards__first';
    }

    return index === 1 ? 'section-sure__cards__second' : ''
  }

  const initTopSectionInfo = () => {
    let infoSection = document.getElementsByClassName('section-information');
    setInfoSection((infoSection[0] as HTMLElement).offsetTop);
  }

  // const onDownloadDoc = (textDownload: string, href: string) => {
	// 	fetch(href)
	// 	.then(response => response.blob())
	// 	.then(blob => {
	// 	 const url = URL.createObjectURL(blob);
	
	// 	 var link = document.createElement("a");
	// 	 link.download = textDownload;
	// 	 link.href = url;
	// 	 document.body.appendChild(link);
	// 	 link.click();
	// 	 document.body.removeChild(link);
	// 	});
	// }
  const onDownloadDoc = (textDownload: string, href: string) => {
    fetch(href)
    .then(response => response.blob())
    .then(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
    });
}


  const TABS_CREDIT_INSURENCE_PERCENTAGE = [
    {
      title: 'Requisitos',
      body: (
        <div className="section-information__tabs__tab-requirements">
          <ul>
            <li>Contar con una edad mínima de 18 años y un máximo de 75 años con 364 días.</li>
            <li>Tener un crédito vigente en SURGIR.</li>
          </ul>
        </div>
      )
    },
    {
      title: 'Lo que no cubrimos',
      body: (
        <div className="section-information__tabs">
            <ul>
              <li>Según lo definido por la Compañía de Seguros en los siguientes documentos:</li>
            </ul>
           <div>
          <div>
          <SurgirDownloadIcon
            text={'Certificado de Seguro de Desgravamen Porcentual (PDF/560 KB)'}
            href={process.env.REACT_APP_SEGDESPOR_CERTIFICADO}
            underline={true}
          />
          </div>

          <div>
          <SurgirDownloadIcon
            text={'Certificado de Seguro de Desgravamen Porcentual con devolución (PDF/560 KB)'}
            href={process.env.REACT_APP_SEGDESPOR_CERTIFICADO_DEVOLUCION}
            underline={true}
          />
          </div>
        </div>
        </div>
      )
    },
    {
      title: 'Documentos',
      body: (
        <div>
          <div>
            <SurgirDownloadIcon
            text={'Certificado de Seguro de Desgravamen Porcentual (PDF/560 KB)'}
            href={process.env.REACT_APP_SEGDESPOR_CERTIFICADO}
            underline={true}
            />

          </div>

          <div>
            <SurgirDownloadIcon
            text={'Certificado de Seguro de Desgravamen Porcentual con devolución (PDF/560 KB)'}
            href={process.env.REACT_APP_SEGDESPOR_CERTIFICADO_DEVOLUCION}
            underline={true}
            />
          </div>

          <div>
          <SurgirDownloadIcon
            text={'Beneficios riesgos y condiciones - Operaciones Activas (PDF/265 KB)'}
            href={process.env.REACT_APP_SEGDESPOR_BENEFICIOS_CONDICIONES }
            underline={true}
          />
          </div>
        </div>

      )
    }
  ]

  const [animation, setAnimation] = useState('open')

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const downloadDoc = (title: string, url: string) => {
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
     const url = URL.createObjectURL(blob);

     var link = document.createElement("a");
     link.download = title;
     link.href = url;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
    });
  }


  return (
    <div>

      <SurgirBanner
        showButton={false}
        textButton="Solicítalo ahora"
        title={'Seguro de Desgravamen Porcentual'}
        description={'Nos preocupamos por tu familia en momentos difíciles.'}
        image={'banner-desgravamen-porcentual.png'}
        showFooter={true}
        imageFooter={'logo-mapfre.svg'}
        backgroundColor={'#F6FAFC'}
        altImgBanner="Banner Seguro Desgravamen Porcentual Devolución SURGIR"
      />

      <div className="section-benefits">
        <Container>
          <div className="title-section">
            Conoce los beneficios del Seguro de Desgravamen Porcentual
          </div>

        </Container>

        <Container>
          <div className="section-benefits__cards">

            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={0}
              slidesPerView={3}
              navigation={isDesktop ? true : false}
              pagination={{ clickable: true }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0
                }
              }}
            >
              <SwiperSlide className="SwiperSlide-container">
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/percentage-credit/places-transport.svg" alt="super seguro" />
                    <p>Cobertura en caso de muerte natural o accidental</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/percentage-credit/wheelchair.svg" alt="super seguro" />
                    <p>Protección ante invalidez por accidente o enfermedad</p>
                  </div>

                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/percentage-credit/hands.svg" alt="super seguro" />
                    <p>Pago del total de tu deuda vigente en SURGIR</p>
                  </div>
                </div></SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/percentage-credit/arrows-circle.svg" alt="super seguro" />
                    <p>Podrás recuperar parte de lo pagado</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/percentage-credit/infringements.svg" alt="super seguro" />
                    <p>Págalo junto con la cuota de tu crédito</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

          </div>

        </Container>

      </div>

      <div className="insurances-container">
      <div className="title-section" style={{ marginBottom: '48px' }}>
         Tipos de Seguro de Desgravamen Porcentual
        </div>

        <div className="content-insurance">
          {
            TYPESINSURANCE.map((type, index) => {
              return (
                <div className="card-insurance" key={index}>
                  <img src={`images/percentage-credit/coverage/${type.icon}.svg`} alt="super seguro" />
                  <div>
                    <span>{type.title}</span>
                    <p>{type.description}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <div className="section-toppings-porc">
        <div className="title-section title-toppings" style={{ marginBottom: '48px' }}>
        ¿En qué cubre nuestro Seguro de Desgravamen Porcentual?
        </div>

        <Container className="p-0 container-toppings">
          <div className="section-toppings-porc__cards">
            {isAcordionLg ? ( 
              COVERAGE.map((coverage, index) => {
                return (
                  index < showCardsCoverage &&
                  <div className="section-toppings-porc__cards__card" key={index}>
                    <div className="section-toppings-porc__cards__card__icon">
                      <img src={`images/percentage-credit/coverage/${coverage.icon}.svg`} alt="super seguro" />
                    </div>

                    <div className="section-toppings-porc__cards__card__content">
                      <h5>{coverage.title}</h5>
                      <p>{coverage.description}</p>
                    </div>

                  </div>
                )
              })
            ) : (  
              
            //   COVERAGE.map((coverage, index) => {
            //   return (
            //     index < showCardsCoverage &&
            //     <div className="section-toppings-porc__cards__card" key={index}>
            //       <div className="section-toppings-porc__cards__card__icon">
            //         <img src={`images/percentage-credit/coverage/${coverage.icon}.svg`} alt="super seguro" />
            //       </div>

            //       <div className="section-toppings-porc__cards__card__content_acordion">
            //         <h5>{coverage.title}</h5>
            //         <img src="icons/percentage-credit/chevron down.svg" alt="icon-arrow-action" />
            //       </div>

            //     </div>
            //   )
            // }) 
            <AccordionIcon items={COVERAGE} />
            ) }
           
          </div>


          <div className="section-toppings-porc__notice">* Aplicable solo para el Seguro de Desgravamen Porcentual con Devolución.</div>
          <div className="section-toppings-porc__footer">
            Conoce el detalle de cada una de nuestras <span>
              <span onClick={() => {
                setKeyTabInfo("Documentos");
                window.scrollBy({
                  top: topInfoSection - window.scrollY
                });
              }}>coberturas</span>
            </span>.
          </div>

        </Container>


      </div>

      <div className="section-sure">

        <Container  >
          {
            !tablePlanVisible || mobiletbPlans ? (
              <div className={`Modal ${animation}`}>
                <div className="title-section">
                  {titleSectionPlans}
                </div>

                <div className="section-sure__cards">
                  {
                    PLANS.map((plan, index) => {

                      return (

                        <div key={plan.title} className={classNames
                          ("section-sure__cards__card",
                            { 'section-sure__cards__first': index === 0 },
                            { 'section-sure__cards__second': index === 1 },
                            { 'section-sure__cards__thirth': index === 2 },
                          )
                        }>
                          <h4 className={
                            classNames(
                              "section-sure__cards__card__title",
                              { 'section-sure__cards__first': index === 0 },
                              { 'section-sure__cards__second': index === 1 },
                              { 'section-sure__cards__thirth': index === 2 },
                            )
                          }
                          >
                            {plan.title}
                          </h4>
                          <h6 className="section-sure__cards__card__text" > Desde <b>{plan.amount}</b> del saldo capital <span>{plan.description}</span></h6>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ) : (
              <div></div>
            )


          }

          {
            tablePlanVisible ? (
              <div className={`Modal ${animation}`}>
                <div className="section-plans__table">
                  <Table>
                    {
                      !mobiletbPlans ? <thead>
                      <tr>
                        <th style={{ paddingLeft: '0px' }}>
                          <div className="section-plans__table__head__title">
                            {titleSectionPlans}
                          </div>
                        </th>

                        {
                          PLANS.map((plan, index) => {

                            return (
                              
                              <th key={plan.title}>
                              
                                <div className={
                                  classNames(
                                    "section-plans__cards__card",
                                    styleCardsPlans(index)
                                  )
                                }
                                  style={{ padding: '7px' }}
                                >
                                  <h5 className={
                                    classNames(
                                      "section-plans__cards__card__title",
                                      styleCardsPlans(index)
                                    )
                                  }
                                  >
                                    {plan.title}
                                  </h5>
                                  <h6 className="section-sure__cards__card__text" > Desde <b>{plan.amount}</b> del saldo capital <span>{plan.description}</span></h6>

                                  {/* <h6 className="section-plans__cards__card__text" >{plan.amount} </h6>
                                  <span className="section-plans__cards__card__des" >{plan.description}</span> */}
                                </div>
                              </th>

                            )
                          })
                        }

                      </tr>
                    </thead> : null 
                    }
                    <tbody>

                      {
                        TABLE_PLANS.map((plan) => {
                          return (
                            <>

                              {
                                mobiletbPlans ? (
                                  <tr key={plan.icon}>
                                    <td style={{ paddingBottom: '32px !important' }} colSpan={2}>
                                      <div className="section-plans__table__body__title" style={{ alignItems: 'flex-start' }}>
                                        {/* <img src={`images/safe/${plan.icon}.svg`} alt="super seguro" /> */}
                                        <span>{plan.titlePlan}</span>
                                      </div>
                                    </td>
                                  </tr>
                                ) : (null)

                              }
                              <tr key={plan.icon}>
                                <td style={{ paddingLeft: '0px', display: mobiletbPlans ? 'none' : '' }}>
                                  <div className="section-plans__table__body__title">
                                    {/* <img src={`images/safe/${plan.icon}.svg`} alt="super seguro" /> */}
                                    <span>{plan.titlePlan}</span>
                                  </div>
                                </td>


                                {
                                  plan.values.map((value, index) => {
                                    return (
                                      <td key={value.id}>
                                        <div
                                          className={
                                            classNames(
                                              "section-plans__table__body__content",
                                              
                                            )}
                                        >
                                          <div className="section-plans__table__body__content__title">
                                            {value.title}
                                          </div>
                                          <div 
                                            className={
                                              classNames(
                                                { 'section-plans-porc__table__body__content__first': index === 0 },
                                                { 'section-plans-porc__table__body__content__second': index === 1 },
                                                { 'section-plans-porc__table__body__content__thirth': index === 2 },
                                              )
                                            }
                                          >
                                            <span >{value.amount}</span>
                                          </div>
                                          {
                                            (value.description) && <div><span>por día</span></div>
                                          }
                                        </div>
                                      </td>
                                    )

                                  })
                                }

                              </tr>

                            </>

                          )
                        })
                      }
                    </tbody>
                  </Table>

                </div>

                <div className="section-plans__conditions">
                  <div>* Para el mancomunado (conyugal) el precio es 0.20% mensual del saldo capital.</div>
                </div>

                <div className="section-plans__link">
                  <div className="section-plans__link__body" onClick={() => setTablePlanVisible(false)} >
                    <h6>Ver menos</h6>
                    <span>
                      <img src="icons/safe/chevron-up-sm.svg" alt="super seguro" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="section-plans__link">
                <div className="section-plans__link__body" onClick={() => setTablePlanVisible(true)} >
                  <h6>Ver comparación</h6>
                  <span>
                    <img src="icons/safe/chevron-down-sm.svg" alt="super seguro" />
                  </span>
                </div>
              </div>
            )
          }

        </Container>
      </div>

      <div>
        <SurgirSectionOperation 
          image={'operation-percentage'} 
          ariaLabel={'Banner Caso Seguro Desgravamen Porcentual y con Devolución SURGIR'}
          steppers={OPERATIONS}
        />
      </div>

      

      <SurgirCardsProductCredit
        description={'Obtén uno de los Seguros de Desgravamen Porcentual al solicitar uno de nuestros créditos.'}
      />

      
      <div className="section-simulator-credit">
        <Container>
          <div className="section-simulator-credit--body">
            <div className="section-simulator-credit--body--text">
              <div className="title-section">Simula tu crédito con uno de nuestros Seguros de Desgravamen Porcentual</div>
              <p>Conoce la cuota mensual que estarías pagando</p>

              <div className="section-simulator-credit--body--buttons">
                <SurgirButton
                    onClick={() => console.log('simulador inicial')}
                    variant="danger"
                    radius="24px"
                    width={"199px"}
                    height={"48px"}
                    marginBottom={"0px"}
                    marginTop={"0px"}
                    downloadUrl={process.env.REACT_APP_SEGDESPOR_SIMULADOR_INDIVIDUAL}
                    downloadStyleClass={'linkDescarga'}
                >
                    Simulador individual
                </SurgirButton>
                <SurgirButton
                    onClick={() => console.log('simulador solidario')}
                    downloadUrl={process.env.REACT_APP_SEGDESPOR_SIMULADOR_SOLIDARIO}
                    variant="red"
                    radius="24px"
                    width={"199px"}
                    height={"48px"}
                    marginBottom={"0px"}
                    marginTop={"0px"}
                    downloadStyleClass={'linkDescarga'}
                >
                    Simulador solidario
                </SurgirButton>
              </div>

            </div>
            <div className="section-simulator-credit--body--hero">
              <img src={`images/percentage-credit/container-simulator.png`} alt="simulador seguro" />
            </div>
          </div>
        </Container>
      </div>

      <div className="section-information">
        <Container>
          <div className="title-section">
          Entérate más sobre tu Seguro de Desgravamen Porcentual
          </div>

          {
            mobile || isTabletSm ?

              <div className="section-information__tabs-responsive">
                <SurgirAccordion 
                  sections={TABS_CREDIT_INSURENCE_PERCENTAGE} 
                />

              </div>
              :
              <div className="section-information__tabs">
                <SurgirTabs tabs={TABS_CREDIT_INSURENCE_PERCENTAGE} />
              </div>
          }

        </Container>
      </div>

      <SurgirSectionContact title={'¿Necesitas usar tu Seguro de Desgravamen Porcentual?'} />
    </div>
  );
};

export default CreditInsurancePercentage;
