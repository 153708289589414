import FooterSurgir from "layouts/FooterSurgir/FooterSurgir";
import SurgirNavbar from "layouts/navbar/rcf/Navbar";
import SurgirSearchContainer from "modules/components/Buscador/buscador";
import { Component } from "react";
import { Button } from "react-bootstrap";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga4';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MetaPixel from "utils/MetaPixel";
import Channels from "views/channels/Channels";
import ComplaintsBook from "views/complaints-book/ComplaintsBook";
import Contact from "views/contact/Contact";
import CreditInsurancePercentage from "views/credit-insurance-percentage/CreditInsurancePercentage";
import FixedDeposit from "views/fixed-deposit/FixedDeposit";
import FlexibleFixedDeposit from "views/flexible-fixed-deposit/FlexibleFixedDeposit";
import FormComplaintsBook from "views/form-complaints-book/FormComplaintsBook";
import FormDepositFixedSantander from 'views/form-deposit-fixed-santander/FormDepositFixSantander';
import FormDepositFixed from "views/form-deposit-fixed/FormDepositFixed";
import Home from "views/home/Home";
import ListOfNotaries from "views/list-of-notaries/ListOfNotaries";
import PromotionHistory from "views/promotion-history/PromotionHistory";
import ResponseForm from "views/response-form/ResponseForm";
import ResultSearchView from "views/result-search-view/result-search-view";
import SavingsAccount from "views/savings-account/SavingsAccount";
import Transparency from 'views/transparency/Transparency';
import WhereWeAre from "views/where-we-are/WhereWeAre";
import LinkRoutes from "../constants/LinkRoutes";
import Simulator from "../modules/pages/Simulator/Simulator";
import About from "../views/about/About";
import CreditProsperous from '../views/credit-prosperous/CreditProsperous';
import CreditWomen from '../views/credit-women/CreditWomen';
import FrequencyQuestions from "../views/frequency-questions/FrequencyQuestions";
import RatesAndFees from "../views/rates-and-fees/RatesAndFees";
import RequestCredit from "../views/requestCredit/RequestCredit";
import Safe from "../views/safe/Safe";
import DataProtection from "views/data-protection/DataProtection";
import TermsAndConditions from "views/terms-and-conditions/TermsAndConditions";
import { SurgirModalCookies } from "components/ui/SurgirFormCookies/SurgirFormCookies";

const HomePage = () => (
  <>
    <MetaPixel event="PageView" />
    <Home />
  </>
);

const DepositoPlazoResponsePage = () => (
  <>
    <MetaPixel event="CompleteRegistration" />
    <ResponseForm />
  </>
);

const SolicitaTuCreditoResponsePage = () => (
  <>
    <MetaPixel event="Lead" />
    <ResponseForm />
  </>
);

export default class AdminRoutes extends Component {
  state = {
    modalVisible: false,
    cookiesVisible: true,
    searchContainer: false
  }

  loadReactGAScript() {
    ReactGA.initialize('UA-214558983-1', {
      nonce: 'f81d4c26afadd15dadbcd618376be2028d254ea7805155716a22cf2e1903f489'
    });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }

  componentDidMount() {
    this.loadReactGAScript();
  }

  goTop(){
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  changeVisibleModal = (value: any) => {
    this.setState({ modalVisible: value })
  }
  
  render() {
    return (
      <div className="position-relative">
        <SurgirNavbar onShowSearch={() => {
          this.setState({ searchContainer: !this.state.searchContainer })
        }}/>
        {this.state.searchContainer && <SurgirSearchContainer />} 
        <BrowserRouter>
          <Routes>
            <Route path={LinkRoutes.app} element={<HomePage />} />
            <Route path="/*" element={<Navigate to={LinkRoutes.app} />} />
            
            <Route path={LinkRoutes.creditWomen} element={<CreditWomen />} />
            <Route path={LinkRoutes.creditProsperous} element={<CreditProsperous />} />
            <Route path={LinkRoutes.promotionHistory} element={<PromotionHistory />} />
            <Route path={LinkRoutes.transparency} element={<Transparency />} />
            <Route path={LinkRoutes.resultSearchView} element={<ResultSearchView />} />
            <Route path={LinkRoutes.complaintsBook} element={<ComplaintsBook />} />
            <Route path={LinkRoutes.complaintsBook + '/formulario'} element={<FormComplaintsBook />} />
            <Route path={LinkRoutes.flexibleFixedDeposit} element={<FlexibleFixedDeposit />} />
            <Route path={LinkRoutes.whereWeAre} element={<WhereWeAre />} />
            <Route path={LinkRoutes.channels} element={<Channels />} />
            <Route path={LinkRoutes.dpf} element={<FixedDeposit />} />
            <Route path={LinkRoutes.faqs} element={<FrequencyQuestions />} />
            {/* <Route path={LinkRoutes.creditInsurance} element={<CreditInsurance />} /> */}
            <Route path={LinkRoutes.creditInsurancePercentage} element={<CreditInsurancePercentage />} />
            <Route path={LinkRoutes.aboutUs} element={<About />} />
            <Route path={LinkRoutes.safe} element={<Safe />} />
            <Route path={LinkRoutes.contact} element={<Contact />} />
            <Route path={LinkRoutes.simulator} element={<Simulator/>} /> 
            <Route path={LinkRoutes.listOfNotaries} element={<ListOfNotaries />} />
            <Route path={LinkRoutes.ratesAndFees} element={<RatesAndFees />} />
            <Route path={LinkRoutes.depositFixedSantander} element={<FormDepositFixedSantander />} />
            <Route path={LinkRoutes.formDepositFixed} element={<FormDepositFixed />} />
            <Route path={LinkRoutes.savingsAccount} element={<SavingsAccount />} />
            <Route path={LinkRoutes.responseForm} element={<DepositoPlazoResponsePage />} />
            <Route path={LinkRoutes.requestCredit} element={<RequestCredit />} /> 
            <Route path={LinkRoutes.responseFormRequestCredit} element={<SolicitaTuCreditoResponsePage />} /> 
            <Route path={LinkRoutes.dataProtection} element={<DataProtection />} />
            <Route path={LinkRoutes.termsAndConditions} element={<TermsAndConditions />} />
          </Routes>
          {this.state.cookiesVisible && <CookieConsent
            location="bottom"
            style={{ display: 'block', background: '#f5f9fb', textAlign: "left", color: "#000", padding: "20px" }}
            buttonStyle={{ color: "#fff", background: "#ec0000", fontSize: "14px", padding: "10px" }}
            buttonText="ACEPTAR TODAS LAS COOKIES"
            expires={365}
            cookieSecurity={true}
            onAccept={() => {
              Cookies.set('CookieMarketing', true, { path: '/', secure: true });
		          Cookies.set('CookieStatistics', true, { path: '/', secure: true });
            }}
          >
            <div>
              <span className="cookies-title"> CONFIGURACIÓN DE COOKIES </span>
              <p>
                Esta web utiliza cookies propias y de terceros que son necesarias en tu experiencia de navegación. 
                Con tu aceptación, utilizaremos cookies de marketing y estadísticas. Asimismo; puedes permitirlas o rechazarlas. 
                Para más información ponemos a tu disposición nuestra <a style={{ textDecoration: 'none', color: '#ec0000' }} href="/proteccion-de-datos"> política de cookies</a>.
              </p>
            </div>
            <Button onClick={() => this.changeVisibleModal(true)} variant='outline-danger' style={{ width: 209, paddingLeft: 16, paddingRight: 16, borderRadius: 0, marginTop: 12, marginBottom: -17/*  fontWeight: 'bold' */ }}>
              CONFIGURAR COOKIES
            </Button>
          </CookieConsent>}
      
          <SurgirModalCookies
            show={this.state.modalVisible}
            handleClose={() => this.changeVisibleModal(false)}
            onSendData={(data: any)=>{
              console.log(data)
              this.setState({
                cookiesVisible: false
              })
            } }
          ></SurgirModalCookies>

        </BrowserRouter>

        <FooterSurgir backTop={this.goTop}></FooterSurgir>
      </div>

    );
  }
}
