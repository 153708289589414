import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

const TermsAndConditions: React.FC = () => {
	const listaProveedoresRef = useRef<HTMLDivElement>(null);
	const [scrollPosition, setScrollPosition] = useState(0);

	const handleScroll = () => {
		const position = window.scrollY;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		const hash = window.location.hash;

		if (hash === "#lista-proveedores" && listaProveedoresRef.current) {
			setTimeout(() => {
				const terms = document.getElementById('listProviders')?.offsetTop ?? 0;
				const topBanner = document.getElementById('topBanner')?.scrollHeight ?? 0;

				window.scrollTo({ top: (terms - (56 + (scrollPosition === 0 ? topBanner : 0))) })
			}, 200);
		}
	}, []);

	return (
		<div>
			<div className="fq-header">
				<Container>
					<span className="fq-title">Términos y Condiciones</span>
				</Container>
			</div>
			<Container>
				<div style={{marginTop: "40px", marginBottom: "60px"}}>
					<Row id="termsConditions">

						<Col md={4}>
							<br />
							<p className="Columna">Términos y Condiciones</p>
						</Col>
						<Col md={8}>
							<br />
							<span className="subtitulos"> CONDICIONES DE USO:</span>
							<br /><br />
							Al ingresar a la presente Página Web de SANTANDER FINANCIAMIENTOS S.A (en adelante "SURGIR"), EL USUARIO (cliente de SURGIR) se compromete a leer la totalidad de los Términos y Condiciones generales que se detallan a continuación. En caso EL USUARIO no se encontrase de acuerdo con los mismos o de no aceptarlos, deberá abstenerse de navegar en la misma y/o manipular cualquier tipo de información vertida en él, bajo su responsabilidad.
							En consecuencia, todas las visitas que realice EL USUARIO, en esta página, así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a la legislación.

							<br /><br />
							En consecuencia, todas las visitas que realice EL USUARIO, en esta página, así como sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a la legislación.
							<br /><br />
							La aceptación de los presentes Términos y Condiciones es imprescindible para el registro, acceso, uso, operación, reserva, búsqueda y toda actividad que realice EL USUARIO, quien declara haber leído y aceptado lo estipulado en el presente documento.
							<br /><br />
							Se informa que los datos personales proporcionados a SURGIR quedan incorporados al Banco de Datos denominado “CLIENTES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21483 de titularidad de SURGIR, quien utilizará dicha información para efectos de la gestión de los productos y/o servicios solicitados y/o contratados (incluyendo evaluaciones financieras, procesamiento de datos, formalizaciones contractuales, cobro de deudas, gestión de operaciones financieras y remisión de correspondencia, entre otros), la misma que podrá ser realizada a través de terceros.
							<br /><br />
							SURGIR protege estos bancos de datos y sus tratamientos con todas las medidas necesarias para garantizar su seguridad y evitar la alteración, pérdida, tratamiento o acceso no autorizado.
							<br /> <br />A continuación, se exponen dichos Términos y Condiciones:
							<br /> <br />
							1. El Usuario:
							<br /> <br />
							A. De su Registro:
							<br />
							Será necesaria para el uso de los productos ofrecidos por SURGIR
							la aceptación de las presentes condiciones y el registro por parte
							de EL USUARIO de los diversos datos personales, tales como:
							nombres y apellidos, tipo y documento nacional de identidad,
							correo electrónico, número de celular y otros según corresponda.
							Asimismo, EL USUARIO declara que información brindada es veraz,
							actual, suficiente y autentica.
							<br />
							<br />
							B. Derechos al acceder a la Página Web
							<br />
							SURGIR garantiza que EL USUARIO gozará de todos los derechos que
							le reconoce la legislación sobre protección al consumidor y
							protección de datos personales vigentes en el Perú.
							<br />
							La sola visita a esta página no impone a EL USUARIO a la
							adquisición de los diversos productos, a menos que haya aceptado
							las condiciones ofrecidas por SURGIR.
							<br />
							SURGIR garantiza en particular que la información sobre las
							características y condiciones de los productos ofrecidos a través
							de esta Página Web será oportuna, suficiente, veraz, apropiada y
							de fácil acceso a EL USUARIO. Dicha información podrá ser modifica
							por SURGIR, incluyendo la relacionada a las características y
							condiciones de cada servicio, en cualquier momento y sin previo
							aviso.
							<br />
							<br />
							C. Obligaciones al acceder a la Página Web
							<br />
							EL USUARIO se obliga a acceder a la Página Web, con la mayor
							diligencia, debiéndose entender que realizará un uso correcto, y
							lícito, evitando en todo momento realizar acciones que puedan
							atentar contra el buen funcionamiento y la imagen de SURGIR o
							cualquier acto que vaya en contra de sus fines.
							<br />
							Por ello, EL USUARIO se obliga a: (i) No utilizar el Página Web
							con fines fraudulentos o no llevar a cabo una conducta que,
							involucrando a la Página Web, dañe o genere un daño a la imagen,
							los intereses y los derechos de SURGIR o de terceros; (ii) No
							suprimir, eludir o manipular el "copyright" y demás datos
							identificativos de los derechos de sus titulares incorporados a
							los contenidos en la Página Web; y, (iii) No realizar cualquier
							acto que involucre dañar, inutilizar o sobrecargar el Página Web,
							o que impidiera, de cualquier forma, la normal utilización y
							funcionamiento del mismo.
							<br /><br />
							Esta lista debe ser entendida como enunciativa más no limitativa,
							pudiendo extenderse a cualquier acto realizado por EL USUARIO que
							conlleve a lograr el perjuicio a SURGIR, a sus clientes u otros
							Usuarios o proveedores.
							<br />
							En caso SURGIR detecte que EL USUARIO realizó cualquiera de las
							prohibiciones nombradas en el párrafo anterior o incluso cuando
							existan indicios o sospechas razonables de ellas, limitará,
							suspenderá o dará por concluido el acceso de EL USUARIO a la
							Página Web. Aunado a ello, SURGIR adoptará las acciones legales y
							técnicas necesarias.
							<br />
							<br />
							2. Limitación de responsabilidad
							<br /> <br />
							EL USUARIO reconoce y acepta que el acceso a la Página Web se
							realiza en todo momento bajo su riesgo y responsabilidad, por lo
							que SURGIR no se responsabiliza del mal uso o acceso indebido que
							pueda realizarse del mismo.
							<br />
							Por tanto, SURGIR no será responsable de lo siguiente:
							<br />
							<br />• El contenido de las páginas enlazados a través de los
							enlaces (links) incluidos dentro del Página Web.
							<br /><br />• De los contenidos publicitarios que estén albergados en la
							Página Web.
							<br />
							En ningún caso SURGIR será responsable de la inexactitud,
							falsedad, irregularidades y/o actos que puedan considerarse
							contrarios a la moral, usos y buenas costumbres que puedan
							contener los materiales publicitarios de los anunciantes; quienes
							asumen directamente toda responsabilidad.
							<br /><br />• Los daños y perjuicios de cualquier tipo causados en los
							equipos informáticos de EL USUARIO por virus, gusanos
							informáticos, troyanos o cualquier otro elemento dañino.
							<br /><br />• Los daños y perjuicios de cualquier tipo producidos por
							fallos o desconexiones en las redes de telecomunicaciones que
							produzcan la suspensión, cancelación o interrupción del acceso al
							Página Web. EL USUARIO reconoce que el acceso al Página Web
							requiere de servicios suministrados por terceros ajenos al control
							de SURGIR.
							<br />
							<br />
							3. Fuerza mayor
							<br />
							<br />
							SURGIR no será responsable de cualquier incumplimiento o retraso
							en el cumplimiento de las diversas obligaciones señaladas en los
							presentes documento, cuando ello sea causado por un hecho que
							escape de su control, caso fortuito, hecho determinante de
							terceros o causado por fuerza mayor.
							<br />
							Se debe entender por “fuerza mayor” a cualquier acto o hecho fuera
							del control razonable de SURGIR, incluyendo, sin limitación, a
							conmoción civil, disturbios, invasión, ataque terrorista o amenaza
							de ataque terrorista, guerra (declarada o no) huelgas u otras
							medidas realizadas por terceros, o acciones realizadas por un
							proveedor generando retraso, incendio, explosión, tormenta,
							inundación, terremoto, hundimiento, epidemia, pandemia o cualquier
							otro desastre natural, o el fracaso de las redes de
							telecomunicaciones públicas o privadas.
							<br />
							De darse un evento de fuerza mayor que afecte al cumplimiento de
							las obligaciones de SURGIR, las obligaciones pactadas y plazos
							serán automáticamente suspendidos hasta la culminación de los
							hechos referidos. Una vez superado el evento de fuerza mayor los
							plazos continuarán con su cómputo.
							<br />
							<br />
							4. Enlaces (links)
							<br />
							<br />
							La Página Web puede incluir enlaces (links) que permitan a EL
							USUARIO a acceder a otras páginas y portales de Internet. En estos
							casos, SURGIR actúa como un prestador de servicios a terceros. En
							el supuesto de que EL USUARIO considere que existe una página
							enlazado con contenidos inadecuados o ilícitos, deberá comunicarlo
							a SURGIR al siguiente correo electrónico:
							atencionalusuario@surgir.com.pe, con la finalidad de evaluar el
							caso y definir las acciones.
							<br />
							SURGIR no se hace responsable del contenido de las páginas
							enlazados, ni de sus condiciones de uso y/o políticas de
							privacidad ni similares, siendo EL USUARIO el único responsable de
							comprobarlos y aceptarlos cada vez que acceda y haga uso de los
							mismos.
							<br />
							SURGIR se reserva el derecho a prohibir o inutilizar en cualquier
							momento los enlaces publicados en el Página Web.
							<br />
							<br />
							5. Validez de la publicidad contenida en el este Página Web
							<br />
							<br />
							La publicidad de los productos que realice SURGIR se rige por las
							disposiciones del Código de Protección de Defensa del Consumidor y
							otras normas que resulten aplicables.
							<br />
							En cada anuncio promocional se indicará de manera clara la
							duración y condiciones del servicio promocionado.
							<br />
							SURGIR se reserva el derecho de poder modificar el contenido de la
							Página Web, incluyendo aquella información relacionada a los
							productos, en cualquier momento y sin mediar previo aviso. Por
							tanto, en caso EL USUARIO haya seleccionado un producto o servicio
							sujeto a alguna promoción y/u oferta, y la misma quedará sin
							efecto antes de la conclusión de la transacción, la selección de
							dicho producto o servicio también concluirá.
							<br />
							La facultad de modificar las condiciones por parte de SURGIR no se
							aplicará a aquellas transacciones que se hayan realizado con éxito
							y que hayan sido confirmadas por la Página Web, a menos que se
							trate de un error obvio e inequívoco, en cuyo caso se aplicará lo
							dispuesto por el párrafo anterior.
							<br />
							<br />
							6. Propiedad intelectual
							<br />
							<br />
							Los derechos de propiedad intelectual sobre la disposición de los
							contenidos de la Página Web (incluidos los derechos conexos), su
							diseño gráfico, los signos distintivos (marcas y nombres
							comerciales), así como los programas de ordenador subyacentes
							(incluidos los códigos fuente) son de exclusiva titularidad de
							SURGIR, por tanto, el derecho de uso y explotación de los mismos,
							son protegidas por la legislación de propiedad intelectual e
							industrial vigentes.
							<br />
							Respecto a los distintos elementos que integran el portal
							(gráficos, fotografías, videos, etc.) el derecho de uso y
							explotación de los mismos corresponden al Grupo Santander, del
							cual es parte SURGIR, y son protegidas por la legislación de
							propiedad intelectual e industrial vigentes.
							<br />
							Queda expresamente prohibido la reproducción, transformación,
							distribución, comunicación pública, puesta a disposición,
							extracción y/o reutilización de la Página Web, sus contenidos y/o
							los signos distintivos de SURGIR.
							<br />
							<br />
							7. Imágenes referenciales de los productos o servicios financieros
							<br /><br />
							EL USUARIO debe tener en cuenta que las fotografías, videos,
							representaciones graficas de los productos mostrados en la Página
							Web, tienen una finalidad orientadora y referencial.
							<br />
							<br />
							{/* <span ref={listaProveedoresRef} id="lista-proveedores"></span> */}
							<span className="subtitulos">PROTECCIÓN DE DATOS:</span> <br />
							<br />SURGIR cumple con informar que, de acuerdo con la Ley N°29733 - Ley de Protección de Datos Personales y su Reglamento aprobado mediante Decreto Supremo N° 003-2013-JUS y las demás disposiciones complementarias, está legalmente autorizada para tratar la información que EL USUARIO proporcionará mediante el presente Página Web, con la finalidad de ejecutar la relación contractual.
							<br />
							<br />Asimismo, SURGIR informa que para dar cumplimiento a las obligaciones y/o requerimientos que se generen en virtud de las normas vigentes en el ordenamiento jurídico peruano y/o en normas internacionales que le sean aplicables, incluyendo, pero sin limitarse a las vinculadas al sistema de prevención de lavado de activos y financiamiento del terrorismo, SURGIR podrá dar tratamiento y eventualmente transferir su información a autoridades y terceros autorizados por ley
							<br />
							<br />EL USUARIO reconoce que estarán incluidos dentro de su información todos aquellos datos, operaciones y referencias a los que SURGIR pudiera acceder en el curso normal de su relación, ya sea por haber sido proporcionados por Usted o por terceros o por haber sido desarrollados por SURGIR, tanto en forma física, oral, electrónica y cualquier otro mecanismo, y que pudieran calificar como Datos Personales conforme a la legislación de la materia.
							<br />
							<br />En virtud de lo señalado, SURGIR a incorporar su información al Banco de Datos denominado “CLIENTES” inscrito en el Registro Nacional de Protección de Datos Personales con Código: RNPDP-PJP N° 21483, así como almacenará, dará tratamiento, procesar y/o transferir su información a sus socios comerciales que figuran en la Página Web www.surgir.com.pe, conforme a los procedimientos que SURGIR determine en el marco de sus operaciones habituales, hasta diez (10) años después de que finalice su relación contractual.

						</Col>

					</Row>
				</div>
			</Container>
		</div>

	)
}

export default TermsAndConditions;