import React from "react";
import './SurgirSectionContact.scss'
import { Container } from "react-bootstrap";


interface SurgirSectionContactProps {
  title: string
}
const SurgirSectionContact: React.FC<SurgirSectionContactProps> = ({
  title
}) => {


  
  return (
    <div className="section-contact">
      <Container>
        <div className="section-contact__body">
          <div className="section-contact__body__content">
            <div className="section-contact__body__content__title">{title}</div>
            <p className="section-contact__body__content__description">Contáctate con Mapfre</p>

            <div className="section-contact__body__content__description__phones">
              <div className="phone-detail">
                <span>Lima</span>
                <div className="section-contact__body__content__span">
                    <img src="icons/percentage-credit/phone-call.svg" alt="icon-arrow-action" />
                    <p>(01) 213-3333</p>
                </div>
              </div>

              <div className="hr-contact"></div>
              <div className="phone-detail">
                <span>Provincias</span>
                <div className="section-contact__body__content__span">
                  <img src="icons/percentage-credit/phone-call.svg" alt="icon-arrow-action" />
                  <p>0 801 1 1133</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section-contact__body__image">
            <img alt='Contacto Mapfre Seguro Vida Super Seguro SURGIR' src="images/safe/image-contact.svg" />
          </div>
        </div>
      </Container>
    </div> 

    

  )
};

export default SurgirSectionContact;