import React from "react";
import {
  Container,
} from "react-bootstrap";
import "./Channels.scss";

import Interbank from "./../../assets/images/InterbankChannels.svg";
import KasNet from "./../../assets/images/KasNet SURGIR.svg";

import BCP from "./../../assets/images/BCP SURGIR.svg";
import BanBif from "./../../assets/images/BanBif SURGIR.svg";
import YAPE2 from "./../../assets/images/YAPE SURGIR 2.svg";
import YAPE from "./../../assets/images/YAPE SURGIR.png";
import InterbankAccordion from './../../assets/images/channels/InterbankLogo.svg';
import KasnetAccordion from './../../assets/images/channels/KasNetLogo.svg';

import { ReactComponent as Negocio } from "./../../assets/images/IconBusiness.svg";
import { ReactComponent as Computadora } from "./../../assets/images/IconComputadora.svg";
import { ReactComponent as Desembolsos } from "./../../assets/images/IconDesembolsos.svg";
import { ReactComponent as Oficina } from "./../../assets/images/IconOficina.svg";
import { ReactComponent as Billetera } from "./../../assets/images/Icons - Banking - ic_wallet_digital.svg";
import BCPAccordion from './../../assets/images/channels/BCPLogo.svg';
import BanBifAccordion from './../../assets/images/channels/BanBifLogo.svg';

import IconChevronUpWhite from "./../../assets/images/channels/chevron-up-white.svg";
import AccordionChannelsSurgir from "./components/AccordionChannelsSurgir/AccordionChannelsSurgir";
import SurgirBanner from "components/ui/SurgirBanner/SurgirBanner";
import LinkRoutes from "constants/LinkRoutes";

const itemsChannelYAPE = [
  {
    icon: Billetera, title: 'Billetera digital', titleLink: 'Ir a Billetera digital', link: 'https://innovacxion.page.link/mVFa',
    list: [
      'Hasta S/ 2,000.00 diarios.',
      'Código de pago: DNI.',
      'Atención: 24 horas.',
      'Cargo fijo por pago: S/ 2.90.',
      'Pago total de una cuota, pago anticipado y pago total del crédito.',  
      
    ],
    subList: [],
    id: 7
  },
]

const itemsChannelBCP = [
  {
    icon: Computadora, title: 'Banca por Internet y App', titleLink: 'Ir a Banca por Internet', link: 'https://bcpzonasegurabeta.viabcp.com/#/iniciar-sesion',
    list: [
      'Sin monto límite.',
      'Código de pago: DNI.',
      'Código para el pago total del crédito: DNI + letra T.',
      'Atención: 24 horas.',
      'Cargo fijo por pago (Banca por internet): S/ 3.00.',
      'Cargo fijo por pago (App): S/ 2.50.',
      'Pago total de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.',

    ],
    subList: [],
    id: 7
  },
  {
    icon: Negocio, title: 'Agente BCP', titleLink: 'Ver ubicaciones', link: 'https://www.viabcp.com/canales-presenciales',
    list: [
      'Más de 17,000 agentes a nivel nacional.',
      'Hasta S/ 1,000 por transacción.',
      'Código de pago: DNI.',
      'Código para el pago total del crédito: DNI + letra T.',
      'Atención:',
      'Cargo fijo por pago: S/ 3.00.',
      'Pago total de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.'
    ],
    subList: [
      'Lunes a viernes de 9:00 a. m. a 8:00 p. m.',
      'Sábado de 9:00 a. m. a 8:00 p. m.',
      'Domingos y feriados (horario discrecional).'
    ],
    id: 6
  },
  {
    icon: Oficina, title: 'Oficina BCP', titleLink: 'Ver ubicaciones', link: 'https://www.viabcp.com/canales-presenciales',
    list: [
      'Sin monto límite.',
      'Código de pago: DNI.',
      'Código para el pago total del crédito: DNI + letra T.',
      'Atención:',
      'Cargo fijo por pago: S/ 3.00.',
      'Pago total de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.'
    ],
    subList: [
      'Lunes a viernes de 9:00 a. m. a 6:00 p. m.',
      'Sábado de 9:00 a. m. a 1:00 p. m.'
    ],
    id: 5
  }
]

const itemsChannelBanbif = [
  {
    icon: Computadora, title: 'Banca por Internet y App', titleLink: 'Ir a Banca por Internet', link: 'https://www.banbif.com.pe/Personas',
    list: [
      'Sin monto límite.',
      'Código de pago: DNI.',
      'Atención: 24 horas.',
      'Cargo fijo por pago: S/ 5.00.',
      'Pago total de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.'
    ],
    subList: [],
    id: 9
  },
  {
    icon: Oficina, title: 'Oficina BanBif', titleLink: 'Ver ubicaciones', link: 'https://www.banbif.com.pe/personas/Oficinas-BanBif',
    list: [
      'Sin monto limite.',
      'Código de pago: DNI.',
      'Atención:',
      'Cargo fijo por pago: S/ 5.00.',
      'Pago total de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.',
    ],
    subList: [
      'Lunes a viernes de 9:00 a. m. a 6:00 p. m.',
      'Sábado de 9:00 a. m. a 1:00 p. m.'
    ],
    id: 8
  },
  {
    icon: Desembolsos, title: 'Desembolsos', titleLink: '', link: '',
    list: [
      'Atención:',
    ],
    subList: [
      'Lunes a viernes de 9:00 a. m. a 6:00 p. m.',
      'Sábado de 9:00 a. m. a 1:00 p. m.'
    ],
    id: 10
  }
]

const itemsChannelInterbank = [
  {
    icon: Computadora, title: 'Banca por Internet y App', titleLink: 'Ir a Banca por Internet', link: 'https://interbank.pe/puntos-de-atencion/agentes',
    list: [
      'Sin monto limite.',
      'Código de pago: DNI.',
      'Atención: 24 horas.',
      'Cargo fijo por pago: Sin costo.',
      'Pago total de una cuota, pago parcial de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.',
    ],
    subList: [],
    id: 1
  },
  {
    icon: Negocio, title: 'Agente Interbank', titleLink: 'Ver ubicaciones', link: 'https://interbank.pe/puntos-de-atencion/agentes',
    list: [
      'Más de 3,000 agentes a nivel nacional.',
      'Hasta S/ 1,000 por transacción.',
      'Código de pago: DNI.',
      'Atención:',
      'Cargo fijo por pago: S/ 4.00',
      'Pago total de una cuota, pago parcial de una cuota, adelanto de cuotas, pago anticipado y pago total del crédito.'
    ],
    subList: [
      'Lunes a viernes de 9:00 a. m. a 6:00 p. m.',
      'Sábado de 9:00 a. m. a 1:00 p. m.',
      'Domingos y feriados (horario discrecional).'
    ],
    id: 0,
  }
]

const itemsChannelKasnet = [
  {
    icon: Negocio, title: 'Agente Kasnet', titleLink: 'Ver ubicaciones', link: 'https://ubicatuagente.agentekasnet.com/',
    list: [
      'Más de 14,000 agentes a nivel nacional.',
      'Hasta S/ 1,000 por transacción.',
      'Código de pago: DNI.',
      'Atención:',
      'Cargo fijo por pago: Sin costo.',
      'Pago total de una cuota, pago parcial de una cuota, pago anticipado y pago total del crédito.'
    ],
    subList: [
      'Lunes a viernes de 9:00 a. m. a 6:00 p. m.',
      'Sábado de 9:00 a. m. a 8:00 p. m.'
    ],
    id: 2
  }
]

const redirectPromotions= () => {
  window.open(LinkRoutes.promotionHistory,'_blank');
};

const Channels: React.FC<any> = () => {

  return (
    <div>

      <SurgirBanner
        title={'Gana con la Cuota Regalona'}
        description={'Adelanta tu cuota y participa por un Gift Card S/ 100'}
        image={'channels-cuota-regalona.png'}
        imageFooter={'logo-santander-red.svg'}
        showButton={true}
        textButton="Promociones"
        showFooter={false}
        backgroundColor={'#FFFFFF'}
        altImgBanner="Alt_ Cuota Regalona, Adelanto de Cuota, Canal de Pago, Pago de Cuota, Cuota Mensual, SURGIR"
        eventClickButton={redirectPromotions}
        textBoldDescription={'¡4 SORTEOS y más de 100 ganadores!'}
      />

      <div className="canales-digitales">
        <div
          className="canales-digitales__header"
        >
          <span className="canales-digitales__header__title">
            Elige el canal más conveniente para ti
          </span>
          <p className="canales-digitales__header__description"
          >
            Paga tus cuotas a través de nuestros canales de pago:
          </p>


        </div>

        <div className="channels">
          <a href="/canales#yape">
            <div className="channels__square-channels bcp" style={{ background: " #742384" }}>
              <div className="channels__square-channels__logo-square">
                <img src={YAPE} alt="Canales de pago Yape SURGIR" />
              </div>
            </div>
          </a>
          <a href="/canales#bcp">
            <div className="channels__square-channels bcp" style={{ background: "#004388" }}>
              <div className="channels__square-channels__logo-square">
                <img src={BCP} alt="Canales de pago SURGIR BCP" />
              </div>
            </div>
          </a>

          <a href="/canales#banbif">
            <div className="channels__square-channels banbif" style={{ background: "#20A3FF" }}>
              <div className="channels__square-channels__logo-square">
                <img src={BanBif} alt="Canales de pago SURGIR BanBif" />
              </div>
            </div>
          </a>

          <a href="/canales#interbank">
            <div className="channels__square-channels interbank" style={{ background: "#05BE50" }}>
              <div className="channels__square-channels__logo-square">
                <img src={Interbank} alt="Canales de pago SURGIR Interbank" />
              </div>
            </div>
          </a>

          <a href="/canales#kasnet">
            <div className="channels__square-channels kasnet" style={{ background: "#1B4C90" }}>
              <div className="channels__square-channels__logo-square">
                <img src={KasNet} alt="Canales de pago SURGIR Kasnet" />
              </div>
            </div>
          </a>


        </div>

        <Container>
          <div className="channel-section">
            <div id="yape" className="accordion-section">
              <div className="accordion-section__accordion">
                <div className={"header " + (BCPAccordion ? 'accordion-section__accordion__yape' : '')}>
                  <img alt="Pago de cuotas Yape SURGIR" src={YAPE2} />
                </div>


                <AccordionChannelsSurgir
                  items={itemsChannelYAPE}
                >
                </AccordionChannelsSurgir>

              </div>
            </div>
            <div id="bcp" className="accordion-section">
              <div className="accordion-section__accordion">
                <div className={"header " + (BCPAccordion ? 'accordion-section__accordion__bcp' : '')}>
                  <img alt="Canales de pago SURGIR BCP" src={BCPAccordion} />
                </div>


                <AccordionChannelsSurgir
                  items={itemsChannelBCP}
                >
                </AccordionChannelsSurgir>

              </div>
            </div>

            <div id="banbif" className="accordion-section">
              <div className="accordion-section__accordion">
                <div className={"header " + (BanBifAccordion ? 'accordion-section__accordion__banbif' : '')}>
                  <img alt="Canales de pago SURGIR BanBif" src={BanBifAccordion} />
                </div>


                <AccordionChannelsSurgir
                  items={itemsChannelBanbif}
                >
                </AccordionChannelsSurgir>

              </div>
            </div>

            <div id="interbank" className="accordion-section">
              <div className="accordion-section__accordion">
                <div className={"header " + (InterbankAccordion ? 'accordion-section__accordion__interbank' : '')}>
                  <img alt="Canales de pago SURGIR Interbank" src={InterbankAccordion} />
                </div>

                <AccordionChannelsSurgir
                  items={itemsChannelInterbank}
                >
                </AccordionChannelsSurgir>

              </div>
            </div>

            <div id="kasnet" className="accordion-section">
              <div className="accordion-section__accordion">
                <div className={"header " + (KasnetAccordion ? 'accordion-section__accordion__kasnet' : '')}>
                  <img alt="Canales de pago SURGIR Kasnet" src={KasnetAccordion} />
                </div>

                <AccordionChannelsSurgir
                  items={itemsChannelKasnet}
                >
                </AccordionChannelsSurgir>

              </div>
            </div>







          </div>
        </Container>
      </div>


      <a href="/canales#canales">
        <div className="button-scroll">
          <img className="button-scroll__icon" src={IconChevronUpWhite} alt="" />
        </div>
      </a>

    </div>



  );
}

export default Channels;
