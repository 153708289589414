import { Box, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useSearchParams } from "react-router-dom";
import SurgirDropDown from "../../components/ui/SurgirDropDown/SurgirDropDown";
import { SurgirTextField } from "../../components/ui/SurgirTextField/SurgirTextField";
import ConditionalLink from '../../modules/components/Simulator/components/ConditionalLink';
import { apiGetFindReasoncontact, apiGetFindUbigeoDep, apiGetTypeDocuments, apiPostFindUbigeo, apiPostSaveDpfSolicitudeForm } from "../../service/apiEndPoint";
import { PATTER_ALPH } from "../../utils/constants";
import BannerFormDpf from "./BannerFormDpf";
import "./Contact.scss";
import "./FormDepositFixed.scss";
import ModalSuccess from "./ModalSuccess";
import ToastError from "./ToastError";
import { validateDocumentNumber, validateEmail, validateFieldLastName, validateFieldMont, validateFieldName, validateFieldPhone, validateFieldTypeDocument, validateForm } from "./ValidateField";

interface ContactanosRCFProps {}

interface DocumentType {
  codeTypeDoc: string
  documentType: string
}

interface ListDepartment {
  codeDep: string
  departament: string
}

interface FindReason {
  _id: string
  reasons: string
}

const FormDepositFixSantander: React.FC<ContactanosRCFProps> = (props) => {
  const [monto, setMonto] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [typeDocument, setTypeDocument] = useState<{ value: string; name: string } | null | undefined>(null);
  const [document, setDocument] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameMothers, setLastNameMothers] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState<{ value: string; name: string } | null>(null);
  const [province, setProvince] = useState<{ value: string; name: string } | null>(null);
  const [district, setDistrict] = useState<{ value: string; name: string } | null>(null);
  const [queryType, setQueryType] = useState<{ value: string; name: string } | null>(null);
  const [message, setMessage] = useState("");
  const [listDocumentType, setListDocumentType] = useState<{ value: string; name: string }[]>([]);
  const [listDepartment, setListDepartment] = useState<{ value: string; name: string }[]>([]);
  const [listProvince, setListProvince] = useState<{ value: string; name: string }[]>([]);
  const [listDistrict, setListDistrict] = useState<{ value: string; name: string }[]>([]);
  const [findReasoncontact, setFindReasoncontact] = useState<{ value: string; name: string }[]>([]);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [visibleModalToastSuccess, setVisibleModalToastSuccess] = useState(false);
  const [visibleValidation, setVisibleValidation] = useState(false);
  const [visibleValidationProvincia, setVisibleValidationProvincia] = useState(false);
  const [visibleValidationDistrito, setVisibleValidationDistrito] = useState(false);
  const [visibleValidationDocumento, setVisibleValidationDocumento] = useState(false);
  const [showErrorDocumento, setShowErrorDocumento] = useState(false);
  const [checkPolitics, setCheckPolitics] = useState(false);
  const [checkAccordance, setCheckAccordance] = useState(false);
  const [typeInput, setTypeInput] = useState("number");
  const [messageErrorToas, setMessageErrorToast] = useState<string[]>([]);
  const [showReturnSimulator, setShowReturnSimulator] = useState(false);
  const [captchaRef, setCaptcha] = useState<string | null>(null);

  useEffect(() => {
    apiGetTypeDocuments({ codeForm: 'DpfForm' })
      .then((response) => response.json())
      .then((data) =>
        setListDocumentType(
          data.data.map((item: DocumentType) => ({
            value: item.codeTypeDoc,
            name: item.documentType,
          }))
        )
      )
      .catch((err) => console.log(err));
    apiGetFindUbigeoDep()
      .then((response) => response.json())
      .then((data) =>
        setListDepartment(
          data.result.map((item: ListDepartment) => ({
            value: item.codeDep,
            name: item.departament,
          }))
        )
      )
      .catch((err) => console.log(err));
    apiGetFindReasoncontact()
      .then((response) => response.json())
      .then((data) =>
        setFindReasoncontact(
          data.data.map((item: FindReason) => ({
            value: item._id,
            name: item.reasons,
          }))
        )
      )
      .catch((err) => console.log(err));

    if (
      searchParams.get("product") !== undefined &&
      searchParams.get("product") !== null &&
      searchParams.get("product") !== ""
    ) {
      if (searchParams.get("product") === "Crédito Mujeres Unidas") {
        setQueryType({ value: "02", name: "Solicita el Crédito Mujeres Unidas" });
        setShowReturnSimulator(true);
      }
      if (searchParams.get("product") === "Crédito Prospera") {
        setQueryType({ value: "03", name: "Solicita el Crédito Prospera" });
        setShowReturnSimulator(true);
      }
      if (searchParams.get("product") === "Crédito Construyendo Sueños") {
        setQueryType({ value: "02", name: "Solicita el Crédito Construyendo Sueños" });
        setShowReturnSimulator(true);
      }
    }

    return () => {};
  }, []);

  const envioFormulario = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleModalToastSuccessClose();
    const request = {
      firstName: firstName,
      secondName: secondName,
      lastName: lastName,
      otherLastName: lastNameMothers,
      typeDOI: { code: typeDocument?.value ?? "", name: typeDocument?.name ?? "" },
      numberDOI: document,
      mobilePhone: phone,
      email: email,
      import: parseFloat(monto.replaceAll(",","").replace("S/","") ?? "0.00"),
      procesingPersonalData: checkPolitics
    };

    if(!captchaRef){
      let currentMessage: string[] = [];
      currentMessage.push("Pendiente completar correctamente la verificación.");
      handleModalToastSuccessShow();
      setMessageErrorToast(currentMessage);
      return
    }
    const { complete } = validateForm(request);
    if (complete && checkAccordance && checkPolitics) {
      apiPostSaveDpfSolicitudeForm(request)
        .then((response) => {
          if (response.status > 300) {
            let currentMessage: string[] = [];
            currentMessage.push("Se presentó un error en el envío de la solicitud");
            handleModalToastSuccessShow();
            setMessageErrorToast(currentMessage);
          } else {
            handleModalSuccessShow();
            response.json();
          }
        })
        .catch((error) => console.log(error));
    } else {
      let currentMessage: string[] = [];
      if (!complete) {
        currentMessage.push("Pendiente completar todos los campos correctamente.");
      }
      if (!checkPolitics) {
        currentMessage.push(
          "Pendiente aceptar la “Política de tratamiento de protección de datos personales”"
        );
      }
      if (!checkAccordance) {
        currentMessage.push("Pendiente dar conformidad de la solicitud ingresada");
      }
      setVisibleValidation(true);
      handleModalToastSuccessShow();
      setMessageErrorToast(currentMessage);
    }
  };

  const handleTypeDocument = (e: { value: string; name: string }) => {
    setVisibleValidationDocumento(true);
    setShowErrorDocumento(false);
    setTypeDocument(e);
    setDocument("");
    if (e.value === "02") {
      setTypeInput("text");
    }
    if (e.value === "01" || e.value === "03" || e.value === "04") {
      setTypeInput("number");
    }
  };

  const handleClickDocument = () => {
    if (typeDocument == null) {
      setShowErrorDocumento(true);
    }
  };

  const handleModalSuccessClose = () => {
    setVisibleModalSuccess(false);
    handleResetForm();
  };

  const handleModalToastSuccessClose = () => {
    setVisibleModalToastSuccess(false);
  };

  const handleModalSuccessShow = () => {
    setVisibleModalSuccess(true);
  };

  const handleModalToastSuccessShow = () => {
    setVisibleModalToastSuccess(true);
  };

  const handleResetForm = () => {
    setVisibleValidation(false);
    setTypeDocument(null);
    setDocument("");
    setSecondName("");
    setLastNameMothers("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setMonto("")
    setEmail("");
    setDepartment(null);
    setProvince(null);
    setDistrict(null);
    setCheckPolitics(false);
    setCheckAccordance(false);
  };

  const onChangeDepartament = (value: { value: string; name: string }) => {
    setDepartment(value);
    setProvince(null);
    setDistrict(null);
    setListDistrict([]);

    const request = {
      codeDep: value.value,
      codeProv: "",
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListProvince(
          data.result.responseProvince.map((item: any) => ({
            value: item.codeProv,
            name: item.province,
          }))
        )
      )
      .catch((error) => console.log(error));

    setVisibleValidationProvincia(false);
    setVisibleValidationDistrito(false);
  };

  const onChangeProvince = (value: { value: string; name: string }) => {
    setProvince(value);
    const request = {
      codeDep: "",
      codeProv: value.value,
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListDistrict(
          data.result.responseDistrict.map((item: any) => ({
            value: item.codeDist,
            name: item.district,
          }))
        )
      )
      .catch((error) => console.log(error));
  };

  const onClickProvincia = () => {
    if (department === undefined || department === null) {
      setVisibleValidationProvincia(true);
    }
    setVisibleValidationDistrito(false);
  };

  const onClickDistrito = () => {
    if (province === undefined || province === null) {
      setVisibleValidationDistrito(true);
    }
  };
  const onChangeCaptcha = (val: string | null) => {
     setCaptcha(val);
  };

  return (
    <div className="container-body">

      <div>
        <BannerFormDpf />
      </div>
      <div className="center-div" >
        <div className="container-form" style={{ margin: "0 auto" }}>

          <Box
            component="form"
            onSubmit={(e: any) => envioFormulario(e)}
            autoComplete="off"
          >

            <div style={{ margin: "auto" }}>
              <div className="dpf-title">
                <span>Completa este formulario y nos contactaremos contigo</span>
              </div>
            
              <div className="form-container-components">

                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    disabled={false}
                    type=''
                    dependValue={null}
                    key='firstName'
                    prefix=''
                    value={firstName}
                    setValue={setFirstName}
                    visibleError={visibleValidation}
                    label="Primer nombre"
                    fullWidth={true}
                    variant="filled"
                    validateError={validateFieldName}
                    pattern={PATTER_ALPH}
                  />
                </div>
                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    disabled={false}
                    type=''
                    dependValue={null}
                    key='secondName'
                    prefix=''
                    value={secondName}
                    setValue={setSecondName}
                    visibleError={false}
                    label="Segundo nombre"
                    fullWidth={true}
                    variant="filled"
                    validateError={()=>({error:false,message:""})}
                    pattern={PATTER_ALPH}
                  />

                </div>
              </div>
              <div className="form-container-components">
                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    disabled={false}
                    type=''
                    dependValue={null}
                    key='lastName'
                    prefix=''
                    value={lastName}
                    setValue={setLastName}
                    visibleError={visibleValidation}
                    label="Apellido Paterno"
                    fullWidth={true}
                    variant="filled"
                    validateError={validateFieldLastName}
                    pattern={PATTER_ALPH}
                  />
                </div>
                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    disabled={false}
                    type=''
                    dependValue={null}
                    key='otherLastName'
                    prefix=''
                    value={lastNameMothers}
                    setValue={setLastNameMothers}
                    visibleError={visibleValidation}
                    label="Apellido Materno"
                    fullWidth={true}
                    variant="filled"
                    validateError={validateFieldLastName}
                    pattern={PATTER_ALPH}
                  />
                </div>
              </div>
              <div className="form-container-components">
                <div
                  style={{ margin: "9px 0 8px 0" }}
                >
                  <SurgirDropDown
                    hint={"Tipo de documento"}
                    dataSource={listDocumentType}
                    error={
                      visibleValidation && validateFieldTypeDocument(typeDocument).error
                    }
                    messageError={validateFieldTypeDocument(typeDocument).message}
                    value={typeDocument}
                    onChange={handleTypeDocument}
                  />
                </div>

                <div

                  style={{ margin: "8px 0 8px 0" }}
                >
                  <SurgirTextField
                    type=''
                    key='numberDocument'
                    prefix=''
                    value={document}
                    visibleError={visibleValidation}
                    disabled={
                      typeDocument == undefined ||
                      typeDocument == null ||
                      typeDocument.value == ""
                    }
                    dependValue={typeDocument}
                    validateError={validateDocumentNumber}
                    setValue={setDocument}
                    label="Número de documento"

                    fullWidth={true}
                    variant="filled"
                    pattern={
                      typeDocument?.value === "01"
                        ? /^\d{0,8}$/
                        : typeDocument?.value === "03" || typeDocument?.value === "02"
                          ? /^[a-zA-Z\d]{0,12}$/
                          : typeDocument?.value === "04"
                            ? /^[a-zA-Z\d]{0,15}$/
                            : typeDocument?.value === "05"
                              ? /^\d{0,11}$/
                              : typeDocument?.value === "06"
                                ? /^[a-zA-Z\d]{0,9}$/
                                : /./ // Un patrón predeterminado para permitir cualquier cosa si el tipo de documento no coincide con ninguno de los anteriores.
                    }

                  />
                </div>
              </div>
              <div
                style={{ margin: "8px 0 8px 0" }}
              >
                <SurgirTextField
                  disabled={false}
                  prefix='S/'
                  type=''
                  FormatCustom={true}
                  key='monto'
                  value={monto}
                  setValue={setMonto}
                  visibleError={visibleValidation}
                  label="Monto de apertura"
                  fullWidth={true}
                  variant="filled"
                  validateError={validateFieldMont}
                  pattern={/^\d{0,9}$/g}
                />
              </div>
              <div className="form-container-components">
                <div
                  style={{ margin: "8px 0 8px 0" }}
                >
                  <SurgirTextField
                    disabled={false}
                    prefix=''
                    type=''
                    dependValue={null}
                    key='phone'
                    value={phone}
                    setValue={setPhone}
                    visibleError={visibleValidation}
                    label="Celular de contacto"
                    fullWidth={true}
                    variant="filled"
                    validateError={validateFieldPhone}
                    pattern={/^\d{0,9}$/g}



                  />
                </div>


                <div style={{ margin: "8px 0 8px 0" }}>
                  <SurgirTextField
                    disabled={false}
                    type=''
                    dependValue={null}
                    key='correo'
                    prefix=''
                    value={email}
                    setValue={setEmail}
                    visibleError={visibleValidation}
                    label="Correo electrónico"
                    fullWidth={true}
                    variant="filled"
                    validateError={validateEmail}
                    pattern={/[^\s]/}
                  />
                </div>
              </div>

              {/*   <div style={{ margin: "8px 0 8px 0" }}>
                <SurgirTextField
                  disabled={false}
                  onClick
                  type=''
                  FormatCustom=''
                  dependValue={null}
                  key=''
                  prefix=''
                  value={monto}
                  setValue={setMonto}
                  visibleError={visibleValidation}
                  label="Monto de Apertura"
                  fullWidth={true}
                  variant="filled"
                  validateError={validateMont }                               
                  pattern={'/^9\d{0,8}$/'}
                  //prefix="S/"
                />
              </div> */}
              {/*  <div className="form-container-components-ubigeo">
                <div>
                  <SurgirAutocomplete
                    error={
                      visibleValidation && validateFieldDepartment(department).error
                    }
                    messageError={validateFieldDepartment(department).message}
                    hint={"Departamento"}
                    value={department}
                    onChange={onChangeDepartament}
                    dataSource={listDepartment}
                    required={false}
                    search={"name"}
                  />
                </div>


                <div>
                  <SurgirAutocomplete
                    error={
                      (visibleValidation || visibleValidationProvincia) &&
                      validateFieldProvince(province).error
                    }
                    messageError={
                      visibleValidationProvincia
                        ? "Elige primero un departamento."
                        : validateFieldProvince(province).message
                    }
                    hint={"Provincia"}
                    value={province}
                    onChange={(value) => {
                      onChangeProvince(value);
                      setDistrict(null);
                    }}
                    onClick={onClickProvincia}
                    dataSource={listProvince}
                    required={false}
                    search={"name"}
                    disabled={listProvince.length == 0}
                  />
                </div>


                <div>
                  <SurgirAutocomplete
                    error={
                      (visibleValidation || visibleValidationDistrito) &&
                      validateFieldDistrict(district).error
                    }
                    messageError={
                      visibleValidationDistrito
                        ? "Elige primero un departamento y una provincia."
                        : validateFieldDistrict(district).message
                    }
                    hint={"Distrito"}
                    value={district}
                    onChange={setDistrict}
                    onClick={onClickDistrito}
                    dataSource={listDistrict}
                    required={false}
                    disabled={listDistrict.length == 0}
                    search={"name"}
                  />
                </div>
              </div> */}

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <br />
                <Form.Check
                  id={"accordance"}
                  checked={checkAccordance}
                  onChange={({ target }) =>
                    setCheckAccordance(!checkAccordance)
                  }
                  type="checkbox"
                  label={
                    <span className="parrafo">
                      {" "}
                      Mediante el mensaje enviado manifiesto mi conformidad de
                      la solicitud ingresada.
                    </span>
                  }
                />
                <FormHelperText
                  error={true}
                  style={{
                    display: visibleValidation
                      ? checkAccordance
                        ? "none"
                        : "inherit"
                      : "none",
                    marginLeft: 40,
                  }}
                >
                  {" "}
                  Pendiente dar conformidad de la solicitud ingresada.{" "}
                </FormHelperText>
                <br />
                <Form.Check
                  id={"politics"}
                  checked={checkPolitics}
                  onChange={({ target }) => setCheckPolitics(!checkPolitics)}
                  type="checkbox"
                  label={
                    <span className="parrafo">
                      <span>He leído y acepto la </span>
                      <a
                        className="Links"
                        style={{
                          color: "#257fa4",
                          marginRight: "20px",
                          fontFamily: "Str-Headline-Bold",
                        }}
                        target="_blank"
                        href="/proteccion-de-datos"
                      >
                        {" "}
                        Política de tratamiento de protección de datos
                        personales.{" "}
                      </a>
                    </span>
                  }
                />
                <FormHelperText
                  error={true}
                  style={{
                    display: visibleValidation
                      ? checkPolitics
                        ? "none"
                        : "inherit"
                      : "none",
                    marginLeft: 40,
                  }}
                >
                  {" "}
                  Pendiente aceptar la “Política de tratamiento de protección de
                  datos personales”.{" "}
                </FormHelperText>
              </Form.Group>

              <center>

              <ReCAPTCHA 
                style={{ textAlign: "center" }} 
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''} 
                onChange={onChangeCaptcha} 
              />
              <br />

                <div>
                  <Button
                    style={{
                      width: 180,
                      borderRadius: 32,
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: "16",
                      height: 48,
                      textAlign: "center",
                      marginTop: 30,
                      marginBottom: 50,
                    }}
                    variant="danger"
                    type="submit"
                  >
                    {" "}
                    Enviar solicitud{" "}
                  </Button>


                </div>



                <ConditionalLink
                  show={showReturnSimulator}
                />
                <br />
                <br />
                <br />
                <br />
              </center>
            </div>
          </Box>

          <ModalSuccess
            handleShow={() => { }}
            key={"ModalSuccess"}
            show={visibleModalSuccess}
            //show= {true}
            dni={document}
            handleClose={handleModalSuccessClose}
            handleReset={handleResetForm}
          />
          <ToastError
            handleShow={() => { }}
            key={"ToastError"}
            show={visibleModalToastSuccess}
            handleClose={handleModalToastSuccessClose}
            messages={messageErrorToas}
          />
        </div>
      </div>
    </div>

  );

}
export default FormDepositFixSantander;

