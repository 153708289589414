import React from 'react';
import HeroFormDPF from './../../assets/images/form_dpf/dpf-form.png';
import Santander from './../../assets/images/form_dpf/santander-logo-rojo.svg';
import './FormDepositFixed.scss';

const BannerFormDpf: React.FC = () => {
  return (
    <div className="dpf-container-banner" >
      <div>
        <center>
          <span className="dpf-text-banner">¡Solícita tu Depósito a Plazo Fijo!<br /></span>
          <p className="description-banner-dft">¡Tus ahorros crecen este verano!</p>
        </center>
      </div>
      <div>
        <center>
          <img className="dpf-image"  
          src={HeroFormDPF} 
          alt="Alt DPF_ Sube la temperatura con nuestras súper tasas SURGIR ahorros inversión depósito a plazo fijo Banner Producto" />
        </center>
      </div>
      <br />
      <br />
      <div>
        <center>
          <span className="banner-text" >Con el respaldo de</span>
          <br />
          <img src={Santander} />
        </center>
      </div>
    </div>
  );
}

export default BannerFormDpf;
