import { FC, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Cookies } from "react-cookie-consent";
import "./SurgirFormCookies.scss"

interface ModalCookiesProps {
	show: boolean;
	handleClose: () => void;
	onSendData: (value: boolean) => void;
}

const SurgirModalCookies: FC<ModalCookiesProps> = ({ show, handleClose, onSendData }) => {
	const [permitirMarketing, setPermitirMarketing] = useState(true);
	const [permitirStatistics, setPermitirStatistics] = useState(true);


	const handleModalCookie = () => {
		const cookiesVisible = false;
		onSendData(cookiesVisible);
	};

	const handleGuardarConfiguracion = () => {
		handleModalCookie();
		handleClose();
		Cookies.set('CookieConsent', true, { path: '/', secure: true });
		Cookies.set('CookieMarketing', permitirMarketing, { path: '/', secure: true });
		Cookies.set('CookieStatistics', permitirStatistics, { path: '/', secure: true });
	};

	return (
		<div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Panel de configuración de cookies</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p style={{ margin: "24px 0px" }}>
						Este es el configurador avanzado de categorías de cookies propias y de terceros que utilizaremos durante tu navegación para dar cumplimiento a las siguientes finalidades:
					</p>
					<div className="row" style={{marginBottom: '20px'}}>
						<div className="col-md-5">
							<p>Marketing</p>
						</div>
						<div className="col-md-7">
							<div style={{ display: 'flex', gap: '24px' }}>
								<Form.Check
									type="radio"
									label="Permitir"
									name="marketing"
									id="permitir-marketing"
									checked={permitirMarketing}
									onChange={() => setPermitirMarketing(true)}
								/>
								<Form.Check
									type="radio"
									label="Rechazar"
									name="marketing"
									id="rechazar-marketing"
									checked={!permitirMarketing}
									onChange={() => setPermitirMarketing(false)}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-5">
							<p>Estadísticas</p>
						</div>
						<div className="col-md-7">
							<div style={{ display: 'flex', gap: '24px' }}>
								<Form.Check
									type="radio"
									label="Permitir"
									name="analiticas"
									id="permitir-analiticas"
									checked={permitirStatistics}
									onChange={() => setPermitirStatistics(true)}
								/>
								<Form.Check
									type="radio"
									label="Rechazar"
									name="analiticas"
									id="rechazar-analiticas"
									checked={!permitirStatistics}
									onChange={() => setPermitirStatistics(false)}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={handleGuardarConfiguracion}
						style={{ color: "#fff", background: "#ec0000", fontSize: "14px", padding: "10px", border: "2px" }}
						variant="primary"
					>
						Guardar configuración y seguir
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export { SurgirModalCookies }