import React from 'react';
import './SliderCustom.scss'
import SurgirButton from 'components/ui/SurgirButton/SurgirButton';
import styled from 'styled-components';

interface SliderCustomProps {
  slideCustom: {
    imageDesktop: string;
    title: string;
    description: string;
    boldDescription: string[];
    textButton: string;
    eventButton?: any;
    colorText: string;
    isShade: boolean;
    imageSmall: string;
    imageTablet: string;
    ariaLabel?: string;
    typeButtonBanner?: string;
    subtitle?: string
    secondTextButton?: string;
    secondEventButton?: any;
  }
}

const ContainerSlider = styled.div<{ backgroundImageUrl: string }>`
  background-image: url(${props => props.backgroundImageUrl}) !important;
`;

const SliderCustom: React.FC<SliderCustomProps> = ({
  slideCustom
}) => {

  const isSmall = window.matchMedia('(min-width: 320px) and (max-width: 767px)')?.matches;
  const isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1199px)')?.matches;

  const getBackgroundImageUrl = () => {
    if (isSmall) return `images/home/sliders/${slideCustom.imageSmall}`;
    if (isTablet) return `images/home/sliders/${slideCustom.imageTablet}`;
    return `images/home/sliders/${slideCustom.imageDesktop}`;
  };

  const getBackgroundPositionX = () => {
    if (slideCustom.isShade) {
      return isSmall ? 'center' : 'right';
    }
    return isSmall ? 'center' : '80%';
  }

  const getBackgroundStyle = () => {
    if (!slideCustom.isShade) return 'none';
    return isSmall
      ? 'linear-gradient(180deg, rgba(34, 34, 34, 0.6) 50%, rgba(34, 34, 34, 0) 100%)'
      : 'linear-gradient(90deg, rgba(34, 34, 34, 0.8) 65.62%, rgba(34, 34, 34, 0) 100%)';
  };

  const backgroundImageUrl = getBackgroundImageUrl();
  const backgroundPositionX = getBackgroundPositionX();
  const backgroundStyle = getBackgroundStyle();

  const highlightText = (description: string, textBold: string[]) => {
    const regex = new RegExp(`(${textBold.join('|')})`, 'gi');
    const parts = description.split(regex);

    return parts.map((part) => (
      textBold.includes(part) ?
        <span key={part} style={{ fontFamily: '"Str-Microtext-Bold", sans-serif' }}>{part}</span> :
        part
    ));
  };

  return (
    <ContainerSlider
      aria-label={slideCustom.ariaLabel}
      data-testid="container-slider"
      className="slider-custom__principal"
      backgroundImageUrl={backgroundImageUrl}
      style={{
        backgroundPositionX: backgroundPositionX
      }}
    >
      <div className='slider-custom'>
        <div
          className='container-text'
          style={{
            color: slideCustom.colorText,
            background: backgroundStyle,
          }}
        >
          <div className='slider-custom__content'>
            <div
              className='slider-custom__content__title'
            >
              {slideCustom.title}

            </div>
            {slideCustom.subtitle && <div className='slider-custom__content__subtitle'>
              {slideCustom.subtitle}
            </div>
            }

            {
              <div className='slider-custom__content__description'>
                {
                  slideCustom.boldDescription ? highlightText(slideCustom.description, slideCustom.boldDescription) : slideCustom.description

                }
              </div>
            }

            <div className='buttons-sliders'>
              {
                slideCustom.textButton && <div>
                  <SurgirButton
                    onClick={slideCustom.eventButton}
                    variant="danger"
                    radius={"50px"}
                    width={"auto"}
                    height={"48px"}
                    marginBottom={"0px"}
                    marginTop='0px'
                    fontFamily={"'Str-Microtext-Bold', sans-serif"}
                    padding={"0px 26px"}
                    color={slideCustom.typeButtonBanner === 'secondary' ? '#FFFFFF' : '#ec0000'}
                    colorText={slideCustom.typeButtonBanner === 'secondary' ? '#ec0000' : '#FFFFFF'}
                  >
                    {slideCustom.textButton}
                  </SurgirButton>
                </div>
              }
              {
                slideCustom.secondTextButton && <div>
                <SurgirButton
                  onClick={slideCustom.secondEventButton}
                  variant="danger"
                  radius={"50px"}
                  width={"auto"}
                  height={"48px"}
                  marginBottom={"0px"}
                  marginTop='0px'
                  fontFamily={"'Str-Microtext-Bold', sans-serif"}
                  padding={"0px 26px"}
                  color={slideCustom.typeButtonBanner === 'secondary' ? '#FFFFFF' : '#ec0000'}
                  colorText={slideCustom.typeButtonBanner === 'secondary' ? '#ec0000' : '#FFFFFF'}
                >
                  {slideCustom.secondTextButton}
                </SurgirButton>
              </div>
              }
            </div>
           

          </div>
        </div>
        <div>

        </div>
      </div>
    </ContainerSlider>

  );
};

export default SliderCustom;