import { FormHelperText } from "@mui/material";
import SurgirAutocomplete from "components/ui/SurgirAutocomplete/SurgirAutocomplete";
import SurgirDropDown from "components/ui/SurgirDropDown/SurgirDropDown";
import { SurgirSnackbarContentWrapper } from "components/ui/SurgirSnackbarContentWrapper/SurgirSnackbarContentWrapper";
import { SurgirTextField } from "components/ui/SurgirTextField/SurgirTextField";
import LinkRoutes from "constants/LinkRoutes";
import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { apiGetCreditProduct, apiGetFindUbigeoDep, apiGetTypeDocuments, apiPostFindUbigeo, apiSendCreditRequest } from "service/apiEndPoint";
import { validateCellPhone, validateDocumentNumber, validateEmailv2, validateFieldObject, validateFormRequestCredit, validateLimitLetter } from "utils/ValidateField";
import { PATTER_ALPH } from "utils/constants";
import { DocumentType, ListDepartment } from "views/contact/Contact";
import ToastError from "views/contact/ToastError";
import "./RequestCredit.scss";

export interface ProductType {
  productCode: string;
  productType: string;
}

const RequestCreditComponent: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [typeDocument, setTypeDocument] = useState<
    { value: string; name: string } | null | undefined
  >({
    "value": "01",
    "name": "DNI"
  });

  const [typeCredit, setTypeCredit] = useState<
    { value: string; name: string } | null | undefined
  >(null);

  const [listDocumentType, setListDocumentType] = useState<
    { value: string; name: string }[]
  >([]);

  const [listCreditType, setListCreditType] = useState<
    { value: string; name: string }[]
  >([]);

  const [department, setDepartment] = useState<{
    value: string;
    name: string;
  } | null>(null);

  const [listDepartment, setListDepartment] = useState<
    { value: string; name: string }[]
  >([]);

  const [visibleValidation, setVisibleValidation] = useState(false);
  const [visibleValidationDocumento, setVisibleValidationDocumento] =
    useState(false);
  const [showErrorDocumento, setShowErrorDocumento] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [document, setDocument] = useState("");
  const [captchaRef, setCaptcha] = useState<string | null>(null);
  const [typeInput, setTypeInput] = useState("number");
  const [province, setProvince] = useState<{
    value: string;
    name: string;
  } | null>(null);

  const [district, setDistrict] = useState<{
    value: string;
    name: string;
  } | null>(null);

  const [listDistrict, setListDistrict] = useState<
    { value: string; name: string }[]
  >([]);

  const [listProvince, setListProvince] = useState<
    { value: string; name: string }[]
  >([]);

  const [visibleValidationProvincia, setVisibleValidationProvincia] = useState(false);
  const [visibleValidationDistrito, setVisibleValidationDistrito] = useState(false);
  const [checkAccordance, setCheckAccordance] = useState(false);
  const [checkPolitics, setCheckPolitics] = useState(false);
  const [messageErrorToas, setMessageErrorToast] = useState<string[]>([]);
  const [validateFormError, setValidateError] = useState({
    fullName: false,
    lastName: false,
    cellPhone: false,
    email: false
  });
  const [visibleModalToastSuccess, setVisibleModalToastSuccess] = useState(false);

  const getDisplayStyleContact = () => {
    let displayStyle;

    if (visibleValidation) {
      displayStyle = checkAccordance ? "none" : "inherit";
    } else {
      displayStyle = "none";
    }

    return displayStyle;
  };

  const [messagesError, SetMessagesError] = useState([{}]);
  const [openSnackError, setopenSnackError] = useState(false);

  const openSnackerrorFunction = () => {
    setopenSnackError(true);
  };

  const handleTypeDocument = (e: { value: string; name: string }) => {
    setVisibleValidationDocumento(true);
    setShowErrorDocumento(false);
    setTypeDocument(e);
    setDocument("");
    if (e.value === "02") {
      setTypeInput("text");
    }
    if (e.value === "01" || e.value === "03" || e.value === "04") {
      setTypeInput("number");
    }
  };

  const handleTypeCredit = (e: { value: string; name: string }) => {
    setTypeCredit(e);
  };

  const handleClickDocument = () => {
    if (typeDocument == null) {
      setShowErrorDocumento(true);
    }
  };

  const onChangeDepartament = (value: { value: string; name: string }) => {
    setDepartment(value);
    setProvince(null);
    setDistrict(null);
    setListDistrict([]);

    const request = {
      codeDep: value.value,
      codeProv: "",
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListProvince(
          data.result.responseProvince.map((item: any) => ({
            value: item.codeProv,
            name: item.province,
          }))
        )
      )
      .catch((error) => console.log(error));

    setVisibleValidationProvincia(false);
    setVisibleValidationDistrito(false);
  };

  const onChangeProvince = (value: { value: string; name: string }) => {
    setProvince(value);
    const request = {
      codeDep: "",
      codeProv: value.value,
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListDistrict(
          data.result.responseDistrict.map((item: any) => ({
            value: item.codeDist,
            name: item.district,
          }))
        )
      )
      .catch((error) => console.log(error));
  };

  const onClickProvincia = () => {
    if (department === undefined || department === null) {
      setVisibleValidationProvincia(true);
    }
    setVisibleValidationDistrito(false);
  };

  const onClickDistrito = () => {
    if (province === undefined || province === null) {
      setVisibleValidationDistrito(true);
    }
  };

  const onChangeCaptcha = (val: string | null) => {
    setCaptcha(val);
  };

  const handleModalToastSuccessClose = () => {
    setVisibleModalToastSuccess(false);
  };
  const handleModalToastSuccessShow = () => {
    setVisibleModalToastSuccess(true);
  };

  const redirectResponseForm = () => {
    window.location.href = LinkRoutes.responseFormRequestCredit
  };

  const envioFormulario = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleModalToastSuccessClose();
    const request = {
      typeDocument: typeDocument?.name,
      document: document,
      fullName: firstName.trim(),
      lastName: lastName.trim(),
      phone: phone,
      email: email,
      department: department?.name.trim(),
      province: province?.name.trim(),
      district: district?.name.trim(),
      typeCreditProduct: {
        productCode: typeCredit?.value ?? '',
        productType: typeCredit?.name ?? ''
      },
      readAndAcceptedPolicy: checkAccordance,
      acceptedDataProcessingAgreement: checkPolitics
    };

    const noErrors =  Object.values(validateFormError).every(x => !x);
    const { complete } = validateFormRequestCredit(request);

    if (complete && checkAccordance && captchaRef && noErrors) {
      apiSendCreditRequest(request)
        .then((response) => {
          if (response.status > 300) {
            let currentMessage: string[] = [];
            currentMessage.push("Se presentó un error en el envío de la solicitud");
            handleModalToastSuccessShow();
            setMessageErrorToast(currentMessage);
          } else {
            redirectResponseForm();
            // setShowThankYou(true);
            window.scrollTo({top: 0});
            response.json();
          }
        })
        .catch((error) => {
          SetMessagesError([{
            text: "Se presentó un error en el envío de la solicitud",
          }]);
          openSnackerrorFunction()
        });
    } else {
      let currentMessage: string[] = [];
      if(!noErrors) {
        currentMessage.push(
          "Pendiente completar los campos correctamente."
        );
      }
      if (!complete) {
        currentMessage.push(
          "Pendiente completar todos los campos correctamente."
        );
      }
      if (!checkAccordance) {
        currentMessage.push(
          "Pendiente dar conformidad de la solicitud ingresada"
        );
      }
      if (!captchaRef) {
        currentMessage.push(
          "Pendiente completar correctamente la verificación."
        );
      }
      setVisibleValidation(true);
      handleModalToastSuccessShow();
      setMessageErrorToast(currentMessage);
    }
  };


  const handleResetForm = () => {
    setVisibleValidation(false);
    setTypeDocument({
      "value": "01",
      "name": "DNI"
    });
    setDocument("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setDepartment(null);
    setProvince(null);
    setDistrict(null);
    setCheckPolitics(false);
    setCheckAccordance(false);
    setCaptcha(null);
    setTypeCredit(null);
  };

  const toStartOver = () => {
    handleResetForm();
    setShowThankYou(false);
    window.scrollTo({top: 0});
  }

  useEffect(() => {
    apiGetTypeDocuments({ codeForm: "AssetForm" })
      .then((response) => response.json())
      .then((data) => {
        setListDocumentType(
          data.data.map((item: DocumentType) => ({
            value: item.codeTypeDoc,
            name: item.documentType,
          }))
        );
      })
      .catch((err) => console.log(err));

      apiGetCreditProduct()
      .then((response) => response.json())
      .then((data) => {
        setListCreditType(
          data.response.map((item: ProductType) => ({
            value: item.productCode,
            name: item.productType,
          }))
        );
      })
      .catch((err) => console.log(err));
      

    apiGetFindUbigeoDep()
      .then((response) => response.json())
      .then((data) =>
        setListDepartment(
          data.result.map((item: ListDepartment) => ({
            value: item.codeDep,
            name: item.departament,
          }))
        )
      )
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div style={{ paddingTop: 55 }} className="full-container">
        <div className="full-container_container">
          <div className="first-content">
            <div className="banner-rc">
              <div className="banner-rc_container">
                <div>
                  <div className="banner-rc_container-tl">
                    <span className="banner-rc_title">
                      Acelera el progreso de tu negocio con SURGIR
                    </span>
                  </div>
                  <p className="banner-rc_description">
                    Te ofrecemos capital para invertir en tu negocio.
                  </p>
                </div>
                <img alt="Crédito Solidario Grupal Individual SURGIR Formulario Prospecto Solicitud" src="images/banners/hero-form-tu-solicita-credito.png" />
              </div>
            </div>

            <div className="container-full-content">
                {
                    showThankYou ? <div className="container-full-content_thank_you">
                    <span>¡Muchas gracias por llenar tu solicitud!</span>
                    <p>Nos contactaremos contigo para hacer SURGIR tu negocio.</p>
                    <div className="container-full-content_action">
                      <Button
                        id="btnStartOver"
                        name="button-send-request"
                        className="w-100"
                        variant="danger"
                        type="submit"
                        onClick={toStartOver}
                      >
                        Volver al inicio
                      </Button>
                    </div>
                  </div> : null
                }
              
              {
                !showThankYou ? <div className="container-full-content_container">
                <div className="container-form-rc">
                  <div className="container-form-rc_container-tl">
                    <span className="container-form-rc_title">
                      ¡Solicita aquí el crédito para tu negocio!
                    </span>
                  </div>
                  <div className="container-form-rc_content">
                    <div className="row-rc">
                      <SurgirTextField
                        id="txtName"
                        name="input-name"
                        disabled={false}
                        dependValue={null}
                        key="txtName"
                        value={firstName}
                        setValue={setFirstName}
                        visibleError={visibleValidation}
                        label="Nombres"
                        fullWidth={true}
                        variant="filled"
                        validateError={(v) => {
                          let validate = validateLimitLetter(v);
                          setValidateError(prev => ({...prev, fullName: validate.error}))
                          return validate
                        }}
                        pattern={PATTER_ALPH}
                      />

                      <SurgirTextField
                        id="txtLastName"
                        name="input-last-name"
                        disabled={false}
                        dependValue={null}
                        key="txtLastName"
                        value={lastName}
                        setValue={setLastName}
                        visibleError={visibleValidation}
                        label="Apellidos"
                        fullWidth={true}
                        variant="filled"
                        validateError={(v) => {
                          let validate = validateLimitLetter(v);
                          setValidateError(prev => ({...prev, lastName: validate.error}))
                          return validate
                        }}
                        pattern={PATTER_ALPH}
                      />
                    </div>

                    <div className="row-rc">
                      <SurgirDropDown
                        id="selectTypeDocument"
                        name="select-type-document"
                        hint={"Tipo de documento"}
                        classAutocomplete="w-100"
                        dataSource={listDocumentType}
                        error={
                          visibleValidation &&
                          validateFieldObject(typeDocument).error
                        }
                        messageError={validateFieldObject(typeDocument).message}
                        value={typeDocument}
                        onChange={handleTypeDocument}
                      />

                      <SurgirTextField
                        id="txtNumberDocument"
                        name="input-number-document"
                        type=""
                        key="txtNumberDocument"
                        prefix=""
                        value={document}
                        visibleError={visibleValidation}
                        disabled={
                          typeDocument == undefined ||
                          typeDocument == null ||
                          typeDocument.value == ""
                        }
                        dependValue={typeDocument}
                        validateError={validateDocumentNumber}
                        setValue={setDocument}
                        label="Número de documento"
                        fullWidth={true}
                        variant="filled"
                        pattern={
                          typeDocument?.name === "DNI"
                            ? /^(\d{0,8})$/g
                            : (typeDocument?.value === '04' ? /^(\d{0,12})$/g : /^([a-zA-Z]|\d){0,12}$/g)
                        }
                      />
                    </div>

                    <div className="row-rc">
                      <SurgirTextField
                        id="txtPhone"
                        name="input-phone"
                        disabled={false}
                        dependValue={null}
                        key="txtPhone"
                        value={phone}
                        setValue={(v) => {
                          if(v.length) {
                            /^9.*/g.test(v) && setPhone(v);
                          }else{
                            setPhone(v);
                          }  
                        }}
                        visibleError={visibleValidation}
                        label="Celular"
                        fullWidth={true}
                        variant="filled"
                        validateError={(v) => {
                          let validate = validateCellPhone(v);
                          setValidateError(prev => ({...prev, cellPhone: validate.error}))
                          return validate
                        }}
                        pattern={/^\d{0,9}$/g}
                      />
                      <SurgirTextField
                        id="txtEmail"
                        name="input-email"
                        disabled={false}
                        key="txtEmail"
                        value={email}
                        setValue={setEmail}
                        visibleError={visibleValidation}
                        label="Correo electrónico"
                        fullWidth={true}
                        variant="filled"
                        validateError={(v) => {
                          let validate = validateEmailv2(v);
                          setValidateError(prev => ({...prev, email: validate.error}))
                          return validate
                        }}
                        pattern={/[^\s]/}
                      />
                    </div>

                    <div className="row-rc">
                      <SurgirAutocomplete
                        error={
                          visibleValidation &&
                          validateFieldObject(department).error
                        }
                        messageError={validateFieldObject(department).message}
                        hint={"Departamento"}
                        value={department}
                        onChange={onChangeDepartament}
                        dataSource={listDepartment}
                        required={false}
                        search={"name"}
                        id={"selectDepartment"}
                        name={"select-department"}
                        classContainer="w-100"
                      />

                      <SurgirAutocomplete
                        error={
                          (visibleValidation || visibleValidationProvincia) &&
                          validateFieldObject(province).error
                        }
                        messageError={
                          visibleValidationProvincia
                            ? "Elige primero un departamento."
                            : validateFieldObject(province).message
                        }
                        hint={"Provincia"}
                        value={province}
                        onChange={(value) => {
                          onChangeProvince(value);
                          setDistrict(null);
                        }}
                        onClick={onClickProvincia}
                        dataSource={listProvince}
                        required={false}
                        search={"name"}
                        disabled={listProvince.length == 0}
                        id={"selectProvince"}
                        name={"select-province"}
                        classContainer="w-100"
                      />

                      <SurgirAutocomplete
                        error={
                          (visibleValidation || visibleValidationDistrito) &&
                          validateFieldObject(district).error
                        }
                        messageError={
                          visibleValidationDistrito
                            ? "Elige primero un departamento y una provincia."
                            : validateFieldObject(district).message
                        }
                        hint={"Distrito"}
                        value={district}
                        onChange={setDistrict}
                        onClick={onClickDistrito}
                        dataSource={listDistrict}
                        required={false}
                        disabled={listDistrict.length == 0}
                        search={"name"}
                        id={"selectDistrict"}
                        name={"select-district"}
                        classContainer="w-100"
                      />
                    </div>

                    <SurgirDropDown
                      id="selectTypeCredit"
                      name="select-type-credit"
                      hint={"Tipo de crédito"}
                      classAutocomplete="w-100"
                      dataSource={listCreditType}
                      error={false}
                      messageError={""}
                      value={typeCredit}
                      onChange={handleTypeCredit}
                    />
                  </div>
                  <Row>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        id={"chkAccordance"}
                        name="checkbox-accordance"
                        checked={checkAccordance}
                        className="mb-2"
                        onChange={({ target }) =>
                          setCheckAccordance(!checkAccordance)
                        }
                        type="checkbox"
                        label={
                          <span className="parrafo span-check">
                            {" "}
                            Mediante el mensaje enviado manifiesto mi
                            conformidad de la solicitud ingresada.
                          </span>
                        }
                      />
                      <FormHelperText
                        error={true}
                        style={{
                          display: getDisplayStyleContact(),
                          marginLeft: 40,
                        }}
                      >
                        {" "}
                        Pendiente dar conformidad de la solicitud ingresada.{" "}
                      </FormHelperText>
                      <Form.Check
                        id={"chkPolitics"}
                        name="checkbox-politics"
                        checked={checkPolitics}
                        className="mt-1"
                        onChange={({ target }) =>
                          setCheckPolitics(!checkPolitics)
                        }
                        type="checkbox"
                        label={
                          <span className="parrafo span-check">
                            <span>Autorizo el tratamiento de mis datos personales para fines </span>
                            <a
                              className="Links link-rc"
                              style={{
                                marginRight: "20px",
                                fontFamily: "Str-Headline-Bold",
                              }}
                              target="_blank"
                              href="/proteccion-de-datos"
                            >
                              {" "}
                              comerciales y/o publicitarios.{" "}
                            </a>
                          </span>
                        }
                      />
                    </Form.Group>
                  </Row>

                  <div className="w-100 d-flex justify-content-center">
                    <ReCAPTCHA 
                      style={{ textAlign: "center" }} 
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ""} 
                      onChange={onChangeCaptcha} 
                    />
                  </div>

                  <div className="container-action">
                    <Button
                      id="btnSendRequest"
                      name="button-send-request"
                      className="w-100"
                      variant="danger"
                      type="submit"
                      onClick={(e: any) => envioFormulario(e)}
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </div> : null
              }
            </div>
          </div>
          {/* <div className="terms-request">
            <strong>Términos y condiciones:</strong> Válido solo para personas
            naturales y operaciones en soles. Campaña vigente del 01 de abril al
            15 de mayo de 2024, aplica para Crédito Mujeres Unidas, Crédito
            Prospera y Crédito Construyendo Sueños con un Super Seguro. Premios:
            Noventa y nueve (99) Combos de Electrodomésticos Oster. Participan
            todos los clientes que desembolsen un crédito más un Super Seguro en
            SURGIR durante la vigencia de la presente campaña. Fecha de sorteo:
            20 de mayo de 2024. Se premiará a los ganadores por el cumplimiento
            de los lineamientos de la campaña o hasta agotar stock del premio,
            lo que suceda primero. El premio será entregado en las Oficinas
            detalladas por SURGIR, previa coordinación con el ganador.
            <br />
            <br />
            <strong>Consideraciones importantes:</strong> La entrega de los
            premios se realizará diez (10) días útiles después de la fecha del
            sorteo. El plazo máximo para recoger el premio del Sorteo será hasta
            el 14 de junio de 2024. Para más información de la Campaña <strong>“Mamá que
            resuelve”</strong> visítanos en{" "}
            <a className="link-rc" target="_blank" href="https://www.surgir.com.pe/">
              www.surgir.com.pe.
            </a>{" "}
            Las operaciones o desembolsos de créditos son afectadas al ITF
            (Impuesto de Transacciones Financieras) de 0.005%. Se difunde
            información de conformidad con la normativa vigente. MKT 15.03.2024.
            SANTANDER FINANCIAMIENTOS S.A. RUC 20607400351.
          </div> */}
        </div>
      </div>
      <ToastError
        handleShow={() => {}}
        key="toastError"
        show={visibleModalToastSuccess}
        handleClose={handleModalToastSuccessClose}
        messages={messageErrorToas}
      />
      <SurgirSnackbarContentWrapper
        open={openSnackError}
        onopen={setopenSnackError}
        messages={messagesError}
      />
    </>
  );
};

export default RequestCreditComponent;
