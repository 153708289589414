import React from "react";
import { Button } from 'react-bootstrap';
import "./SurgirButton.scss";
import { createLink } from "utils/urls";

interface SurgirButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  border?: string;
  color?: string,
  colorText?: string,
  height?: any,
  radius?: string,
  width?: any,
  variant: string,
  fontFamily?: string,
  maxWidth?: string,
  marginTop?: string,
  marginBottom?: string,
  padding?: string,
  disabled?: boolean;
  type?: any;
  downloadUrl?: string;
  downloadStyleClass?: string;
  downloadSize?: string;
  fontSize?: string
}

const SurgirButton: React.FC<SurgirButtonProps> = ({
  border,
  color,
  colorText,
  children,
  height,
  onClick, 
  radius,
  width,
  variant,
  fontFamily,
  maxWidth,
  marginTop,
  marginBottom,
  padding,
  disabled=false,
  type,
  downloadUrl,
  downloadStyleClass,
  downloadSize,
  fontSize
}) => {
  const renderButton = () => {
    return (
      <Button
        onClick={onClick}
        variant={variant}
        disabled={disabled}
        style={{
          backgroundColor: color,
          border: border,
          borderRadius: radius,
          height,
          fontFamily: fontFamily ?? '"Str-Microtext-Regular", sans-serif',
          textAlign: "center",
          marginTop: marginTop ?? 16,
          marginBottom: marginBottom ?? 16,
          width,
          maxWidth: maxWidth,
          color: colorText,
          padding: padding ?? "0px",
          fontSize: fontSize
        }}
        type={type ?? 'button'}
      >
        {children}
      </Button>
    );
  };

  const renderDownloadLink = () => {
    return (
      <button
        className={downloadStyleClass}
        onClick={() => createLink(downloadUrl ?? "", "_blank")}
        style={{
          fontSize: downloadSize,
          fontFamily: fontFamily ?? '"Str-Microtext-Regular", sans-serif',
        }}
      >
        {children}
      </button>
    );
  };

  return downloadUrl ? renderDownloadLink() : renderButton();
};

export default SurgirButton;