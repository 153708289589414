import { Box, FormHelperText } from "@mui/material";
import { SurgirSnackbarContentWrapper } from "components/ui/SurgirSnackbarContentWrapper/SurgirSnackbarContentWrapper";
import { SurgirTextArea } from "components/ui/SurgirTextArea/SurgirTextArea";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useSearchParams } from "react-router-dom";
import SurgirAutocomplete from "../../components/ui/SurgirAutocomplete/SurgirAutocomplete";
import SurgirDropDown from "../../components/ui/SurgirDropDown/SurgirDropDown";
import { SurgirTextField } from "../../components/ui/SurgirTextField/SurgirTextField";
import ConditionalLink from '../../modules/components/Simulator/components/ConditionalLink';
import { apiGetFindReasoncontact, apiGetFindUbigeoDep, apiGetTypeDocuments, apiPostFindUbigeo, apiSendContactUsForm } from "../../service/apiEndPoint";
import { validateDocumentNumber, validateEmail, validateFieldObject, validateFieldString, validateForm } from "../../utils/ValidateField";
import { PATTER_ALPH } from "../../utils/constants";
import "./Contact.scss";
import ModalSuccess from "./ModalSuccess";
import ToastError from "./ToastError";

export interface DocumentType {
  codeTypeDoc: string
  documentType: string
}

export interface ListDepartment {
  codeDep: string
  departament: string
}

interface FindReason {
  _id: string
  reasons: string
}

const Contact: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [typeDocument, setTypeDocument] = useState<{ value: string; name: string } | null | undefined>(null);
  const [document, setDocument] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState<{ value: string; name: string } | null>(null);
  const [province, setProvince] = useState<{ value: string; name: string } | null>(null);
  const [district, setDistrict] = useState<{ value: string; name: string } | null>(null);
  const [queryType, setQueryType] = useState<{ value: string; name: string } | null>(null);
  const [message, setMessage] = useState("");
  const [listDocumentType, setListDocumentType] = useState<{ value: string; name: string }[]>([]);
  const [listDepartment, setListDepartment] = useState<{ value: string; name: string }[]>([]);
  const [listProvince, setListProvince] = useState<{ value: string; name: string }[]>([]);
  const [listDistrict, setListDistrict] = useState<{ value: string; name: string }[]>([]);
  const [findReasoncontact, setFindReasoncontact] = useState<{ value: string; name: string }[]>([]);
  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [visibleModalToastSuccess, setVisibleModalToastSuccess] = useState(false);
  const [visibleValidation, setVisibleValidation] = useState(false);
  const [visibleValidationProvincia, setVisibleValidationProvincia] = useState(false);
  const [visibleValidationDistrito, setVisibleValidationDistrito] = useState(false);
  const [visibleValidationDocumento, setVisibleValidationDocumento] = useState(false); // eslint-disable-next-line no-unused-vars
  const [showErrorDocumento, setShowErrorDocumento] = useState(false); // eslint-disable-next-line no-unused-vars
  const [checkPolitics, setCheckPolitics] = useState(false);
  const [checkAccordance, setCheckAccordance] = useState(false);
  const [typeInput, setTypeInput] = useState("number"); // eslint-disable-next-line no-unused-vars
  const [messageErrorToas, setMessageErrorToast] = useState<string[]>([]);
  const [showReturnSimulator, setShowReturnSimulator] = useState(false);
  const [captchaRef, setCaptcha] = useState<string | null>(null);

  const [messagesError, SetMessagesError] = useState([{}]);
  const [openSnackError, setopenSnackError] = useState(false);

  const openSnackerrorFunction = () => {
    setopenSnackError(true);
  };

  const getDisplayStyleContact = () => {
    let displayStyle;

    if (visibleValidation) {
      displayStyle = checkAccordance ? "none" : "inherit";
    } else {
      displayStyle = "none";
    }

    return displayStyle;
  };

  useEffect(() => {
    apiGetTypeDocuments({codeForm:'ContactUs'})
      .then((response) => response.json())
      .then((data) =>
        setListDocumentType(
          data.data.map((item: DocumentType) => ({
            value: item.codeTypeDoc,
            name: item.documentType,
          }))
        )
      )
      .catch((err) => console.log(err));
    apiGetFindUbigeoDep()
      .then((response) => response.json())
      .then((data) =>
        setListDepartment(
          data.result.map((item: ListDepartment) => ({
            value: item.codeDep,
            name: item.departament,
          }))
        )
      )
      .catch((err) => console.log(err));
    apiGetFindReasoncontact()
      .then((response) => response.json())
      .then((data) =>
        setFindReasoncontact(
          data.data.map((item: FindReason) => ({
            value: item._id,
            name: item.reasons,
          }))
        )
      )
      .catch((err) => console.log(err));

    if (
      searchParams.get("product") !== undefined &&
      searchParams.get("product") !== null &&
      searchParams.get("product") !== ""
    ) {
      if (searchParams.get("product") === "Crédito Mujeres Unidas") {
        setQueryType({ value: "02", name: "Solicita el Crédito Mujeres Unidas" });
        setShowReturnSimulator(true);
      }
      if (searchParams.get("product") === "Crédito Prospera") {
        setQueryType({ value: "03", name: "Solicita el Crédito Prospera" });
        setShowReturnSimulator(true);
      }
      if (searchParams.get("product") === "Crédito Construyendo Sueños") {
        setQueryType({ value: "02", name: "Solicita el Crédito Construyendo Sueños" });
        setShowReturnSimulator(true);
      }
    }

    return () => {};
  }, []);

  const envioFormulario = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleModalToastSuccessClose();
    const request = {
      typeDocument: typeDocument?.name,
      document: document,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      otherPhone: otherPhone,
      email: email,
      department: department?.name,
      province: province?.name,
      district: district?.name,
      queryType: queryType?.name,
      message: message,
    };
    const { complete } = validateForm(request);
    if (complete && checkAccordance && captchaRef) {
      apiSendContactUsForm(request)
        .then((response) => {
          if (response.status > 300) {
            let currentMessage: string[] = [];
            currentMessage.push("Se presentó un error en el envío de la solicitud");
            handleModalToastSuccessShow();
            setMessageErrorToast(currentMessage);
          } else {
            handleModalSuccessShow();
            response.json();
          }
        })
        .catch((error) => 
          {
            SetMessagesError([{
              text: "Se presentó un error en el envío de la solicitud",
            }]);
            openSnackerrorFunction()
          });
    } else {
      let currentMessage: string[] = [];
      if (!complete) {
        currentMessage.push("Pendiente completar todos los campos correctamente.");
      }
      if (!checkAccordance) {
        currentMessage.push("Pendiente dar conformidad de la solicitud ingresada");
      }
      if(!captchaRef) {
        currentMessage.push("Pendiente completar correctamente la verificación.");
      }
      setVisibleValidation(true);
      handleModalToastSuccessShow();
      setMessageErrorToast(currentMessage);
    }
  };

  const handleTypeDocument = (e: { value: string; name: string }) => {
    setVisibleValidationDocumento(true);
    setShowErrorDocumento(false);
    setTypeDocument(e);
    setDocument("");
    if (e.value === "02") {
      setTypeInput("text");
    }
    if (e.value === "01" || e.value === "03" || e.value === "04") {
      setTypeInput("number");
    }
  };

  const handleClickDocument = () => {
    if (typeDocument == null) {
      setShowErrorDocumento(true);
    }
  };

  const handleModalSuccessClose = () => {
    setVisibleModalSuccess(false);
    handleResetForm();
  };

  const handleModalToastSuccessClose = () => {
    setVisibleModalToastSuccess(false);
  };

  const handleModalSuccessShow = () => {
    setVisibleModalSuccess(true);
  };

  const handleModalToastSuccessShow = () => {
    setVisibleModalToastSuccess(true);
  };

  const handleResetForm = () => {
    setVisibleValidation(false);
    setTypeDocument(null);
    setDocument("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setDepartment(null);
    setProvince(null);
    setDistrict(null);
    setQueryType(null);
    setMessage("");
    setCheckPolitics(false);
    setCheckAccordance(false);
    setCaptcha(null);
  };

  const onChangeDepartament = (value: { value: string; name: string }) => {
    setDepartment(value);
    setProvince(null);
    setDistrict(null);
    setListDistrict([]);

    const request = {
      codeDep: value.value,
      codeProv: "",
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListProvince(
          data.result.responseProvince.map((item: any) => ({
            value: item.codeProv,
            name: item.province,
          }))
        )
      )
      .catch((error) => console.log(error));

    setVisibleValidationProvincia(false);
    setVisibleValidationDistrito(false);
  };

  const onChangeProvince = (value: { value: string; name: string }) => {
    setProvince(value);
    const request = {
      codeDep: "",
      codeProv: value.value,
      codeDist: "",
    };

    apiPostFindUbigeo(request)
      .then((response) => response.json())
      .then((data) =>
        setListDistrict(
          data.result.responseDistrict.map((item: any) => ({
            value: item.codeDist,
            name: item.district,
          }))
        )
      )
      .catch((error) => console.log(error));
  };

  const onClickProvincia = () => {
    if (department === undefined || department === null) {
      setVisibleValidationProvincia(true);
    }
    setVisibleValidationDistrito(false);
  };

  const onClickDistrito = () => {
    if (province === undefined || province === null) {
      setVisibleValidationDistrito(true);
    }
  };

  const onChangeCaptcha = (val: string | null) => {
    setCaptcha(val);
 };

  return (
    <div>
      <Container style={{ paddingTop: 20, margin: "0 auto" }}>
        <Box
          component="form"
          onSubmit={(e: any) => envioFormulario(e)}
          autoComplete="off"
        >
          <Col md={6} style={{ margin: "auto" }}>
            <div style={{ margin: "80px 0 20px 0" }}>
              <span
                className="title-general-segment"
                style={{ marginTop: 50, textAlign: "left" }}
              >
                Contáctanos
              </span>
              <span className="parrafo">
                Si necesitas ayuda u orientación sobre alguno de nuestros
                productos o servicios, estaremos felices de poder atenderte.
                Llena el formulario y nos contactaremos contigo.
                <br />
                De tratarse de un reclamo, queja o requerimiento, haz{" "}
                <a
                  className="Links"
                  style={{ color: "#257fa4" }}
                  target="_blank"
                  href="/reclamos-y-requerimientos"
                >
                  {" "}
                  clic aquí</a>.
              </span>
            </div>
            <span className="subtitle_form"> Datos personales</span>

            <Row>
              <Col
                md={{ span: 6, offset: 0 }}
                style={{ margin: "9px 0 8px 0" }}
              >
                <SurgirDropDown
                  id="selectTypeDocument"
                  name="select-type-document"
                  hint={"Tipo de documento"}
                  dataSource={listDocumentType}
                  error={
                    visibleValidation && validateFieldObject(typeDocument).error
                  }
                  messageError={validateFieldObject(typeDocument).message}
                  value={typeDocument}
                  onChange={handleTypeDocument}
                />
              </Col>
              <Col
                md={{ span: 6, offset: 0 }}
                style={{ margin: "8px 0 8px 0" }}
              >
                <SurgirTextField
                  id='txtNumberDocument'
                  name='input-number-document'
                  type=''
                  key=''
                  prefix=''
                  value={document}
                  visibleError={visibleValidation}
                  disabled={
                    typeDocument == undefined ||
                    typeDocument == null ||
                    typeDocument.value == ""
                  }
                  dependValue={typeDocument}
                  validateError={validateDocumentNumber}
                  setValue={setDocument}
                  label="Número de documento"
                  fullWidth={true}
                  variant="filled"
                  pattern={
                    typeDocument?.name == "DNI"
                      ? /^(\d{0,8})$/g
                      : /^([a-zA-Z]|\d){0,12}$/g
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ margin: "8px 0 8px 0" }}>
                <SurgirTextField
                  id='txtName'
                  name='input-name'
                  disabled={false}
                  dependValue={null}
                  key=''
                  value={firstName}
                  setValue={setFirstName}
                  visibleError={visibleValidation}
                  label="Nombres"
                  fullWidth={true}
                  variant="filled"
                  validateError={validateFieldString}
                  pattern={PATTER_ALPH}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ margin: "8px 0 8px 0" }}>
                <SurgirTextField
                  id='txtLastName'
                  name='input-last-name'
                  disabled={false}
                  dependValue={null}
                  key=''
                  value={lastName}
                  setValue={setLastName}
                  visibleError={visibleValidation}
                  label="Apellidos"
                  fullWidth={true}
                  variant="filled"
                  validateError={validateFieldString}
                  pattern={PATTER_ALPH}
                />
              </Col>
            </Row>
            <br />
            <span className="subtitle_form"> Contacto y ubicación</span>
            <Row>
              <Col
                md={{ span: 12, offset: 0 }}
                style={{ margin: "8px 0 8px 0" }}
              >
                <SurgirTextField
                  id='txtPhone'
                  name='input-phone'
                  disabled={false}
                  dependValue={null}
                  key=''
                  value={phone}
                  setValue={setPhone}
                  visibleError={visibleValidation}
                  label="Celular de contacto"
                  fullWidth={true}
                  variant="filled"
                  validateError={validateFieldString}
                  pattern={/^\d{0,9}$/g}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ margin: "8px 0 8px 0" }}>
                <SurgirTextField
                  id='txtEmail'
                  name='input-email'
                  disabled={false}
                  key=''
                  value={email}
                  setValue={setEmail}
                  visibleError={visibleValidation}
                  label="Correo electrónico"
                  fullWidth={true}
                  variant="filled"
                  validateError={validateEmail}
                  pattern={/[^\s]/}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SurgirAutocomplete
                  error={
                    visibleValidation && validateFieldObject(department).error
                  }
                  messageError={validateFieldObject(department).message}
                  hint={"Departamento"}
                  value={department}
                  onChange={onChangeDepartament}
                  dataSource={listDepartment}
                  required={false}
                  search={"name"}
                  id={"selectDepartment"}
                  name={'select-department'}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SurgirAutocomplete
                  error={
                    (visibleValidation || visibleValidationProvincia) &&
                    validateFieldObject(province).error
                  }
                  messageError={
                    visibleValidationProvincia
                      ? "Elige primero un departamento."
                      : validateFieldObject(province).message
                  }
                  hint={"Provincia"}
                  value={province}
                  onChange={(value) => {
                    onChangeProvince(value);
                    setDistrict(null);
                  }}
                  onClick={onClickProvincia}
                  dataSource={listProvince}
                  required={false}
                  search={"name"}
                  disabled={listProvince.length == 0}
                  id={"selectProvince"}
                  name={'select-province'}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SurgirAutocomplete
                  error={
                    (visibleValidation || visibleValidationDistrito) &&
                    validateFieldObject(district).error
                  }
                  messageError={
                    visibleValidationDistrito
                      ? "Elige primero un departamento y una provincia."
                      : validateFieldObject(district).message
                  }
                  hint={"Distrito"}
                  value={district}
                  onChange={setDistrict}
                  onClick={onClickDistrito}
                  dataSource={listDistrict}
                  required={false}
                  disabled={listDistrict.length == 0}
                  search={"name"}
                  id={"selectDistrict"}
                  name={'select-district'}
                />
              </Col>
            </Row>
            <br />
            <span className="subtitle_form"> Creación de la consulta </span>
            <br />
            <Row>
              <Col style={{ margin: "8px 0 8px 0" }}>
                <SurgirDropDown
                  id="selectTypeConsult"
                  name="select-type-consult"
                  hint={"Tipo de consulta"}
                  dataSource={findReasoncontact}
                  error={
                    visibleValidation && validateFieldObject(queryType).error
                  }
                  messageError={validateFieldObject(queryType).message}
                  value={queryType}
                  onChange={setQueryType}
                />
              </Col>
            </Row>
            {
              queryType?.name == 'Dónde pago mi cuota' && (
                <Row>
                  <Col style={{ margin: "8px 0 8px 0" }}>
                    Si deseas conocer más acerca de nuestras canales de pago <a href='/canales'> clic aquí </a>
                  </Col>
                </Row>
              )
            }

            {
              queryType?.name == 'Canales de atención' && (
                <Row>
                  <Col style={{ margin: "8px 0 8px 0" }}>
                    Si deseas saber más acerca de nuestras zonas de cobertura <a href='/donde-estamos'> clic aquí </a>
                  </Col>
                </Row>
              )
            }


            <Row>
              <Col sm={12} style={{ margin: "8px 0 8px 0" }}>
                <SurgirTextArea
                  id="txtAreaMessage"
                  name="input-area-message"
                  fullWidth={true}
                  value={message}
                  onChange={({ target }:any) => setMessage(target.value)}
                  label="Mensaje"
                  variant="filled"
                  error={
                    visibleValidation && validateFieldObject(message).error
                  }
                  messageError={validateFieldObject(message).message}
                  maxLength={4000}
                  isDisabled={queryType?.name === 'Dónde pago mi cuota' || queryType?.name === 'Canales de atención'}

                />
              </Col>
            </Row>

            <Row>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <br />
                <Form.Check
                  id={"chkAccordance"}
                  name="checkbox-accordance"
                  checked={checkAccordance}
                  onChange={({ target }) =>
                    setCheckAccordance(!checkAccordance)
                  }
                  type="checkbox"
                  label={
                    <span className="parrafo">
                      {" "}
                      Mediante el mensaje enviado manifiesto mi conformidad de
                      la solicitud ingresada.
                    </span>
                  }
                />
                <FormHelperText
                  error={true}
                  style={{
                    display: getDisplayStyleContact(),
                    marginLeft: 40,
                  }}
                >
                  {" "}
                  Pendiente dar conformidad de la solicitud ingresada.{" "}
                </FormHelperText>
                <br />
                <Form.Check
                  id={"chkPolitics"}
                  name="checkbox-politics"
                  checked={checkPolitics}
                  onChange={({ target }) => setCheckPolitics(!checkPolitics)}
                  type="checkbox"
                  label={
                    <span className="parrafo">
                      <span>Autorizo el tratamiento de mis datos personales para fines </span>
                      <a
                        className="Links"
                        style={{
                          color: "#257fa4",
                          marginRight: "20px",
                          fontFamily: "Str-Headline-Bold",
                        }}
                        target="_blank"
                        href="/proteccion-de-datos"
                      >
                        {" "}
                        comerciales y/o publicitarios.{" "}
                      </a>
                    </span>
                  }
                />
              </Form.Group>
            </Row>
            <div className="w-100 d-flex justify-content-center">
                <ReCAPTCHA 
                  style={{ textAlign: "center" }} 
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''} 
                  onChange={onChangeCaptcha} 
                />
            </div>
            <center>
              <Row>
                <Col>
                  <Button
                    id="btnSendRequest"
                    name="button-send-request"
                    style={{
                      width: 180,
                      borderRadius: 32,
                      fontFamily: "Str-Microtext-Bold",
                      fontSize: "16",
                      height: 48,
                      textAlign: "center",
                      marginTop: 30,
                      marginBottom: 50,
                    }}
                    variant="danger"
                    type="submit"
                  >
                    {" "}
                    Enviar consulta{" "}
                  </Button>
                </Col>
              </Row>
              <ConditionalLink
                show={showReturnSimulator}
              />
              <br />
              <br />
              <br />
              <br />
            </center>
          </Col>
        </Box>

        <ModalSuccess
          handleShow={()=>{}}
          key={"ModalSuccess"}
          show={visibleModalSuccess}
          handleClose={handleModalSuccessClose}
          handleReset={handleResetForm}
        />
        <ToastError
          handleShow={()=>{}}
          key={"ToastError"}
          show={visibleModalToastSuccess}
          handleClose={handleModalToastSuccessClose}
          messages={messageErrorToas}
        />

        <SurgirSnackbarContentWrapper
          open={openSnackError}
          onopen={setopenSnackError}
          messages={messagesError}
        />

      </Container>
    </div>
  );

}
export default Contact;

