
import React from "react";
import './SurgirCardPromotions.scss'
import SurgirButton from "../SurgirButton/SurgirButton";

interface SurgirCardPromotionsProps {
	title: string;
	image: string;
	onClick: () => void;
	alt: string;
	dateStart: string;
	dateEnd: string;
}

const redirectGoogleForms = () => {
 window.open('https://forms.office.com/e/2ZE0EV18ZB', 'WindowName','noopener')
}

const SurgirCardPromotions: React.FC<SurgirCardPromotionsProps> = ({
	title,
	image,
	onClick,
	alt,
	dateStart,
	dateEnd
}) => {

	const formatDate = (dateString: any) => {
    const date = new Date(dateString);

		if (isNaN(date.getTime())) {
      return '';
    }

    const options: Intl.DateTimeFormatOptions = { 
      day: "2-digit", 
      month: "long", 
      year: "numeric" 
    };

    return new Intl.DateTimeFormat("es-ES", options).format(date);
  };

	return (

		<div className="card-promotions " >

			<div className="card-promotions__hero item">
				<img
					alt={alt}
					src={`images/promotions/${image}`}
				/>
			</div>
			<div className="card-promotions__content item"  >
				<div className="card-promotions__content__title">
					{title}
				</div>

				<p data-testid="date-range" 
					className="card-promotions__content__description">
				{formatDate(dateStart)} - {formatDate(dateEnd)}
				</p>


				<div className="card-promotions__content--butons">
					{
						title === 'Programa: Refiere y Gana' &&	<SurgirButton
							onClick={redirectGoogleForms}
							fontFamily='"Str-Microtext-Bold", sans-serif'
							radius='32px'
							padding='6px 24px'
							variant={'danger'}
							marginTop='0px'
							marginBottom='0px'
							border='none'
						>
							Refiere aquí
						</SurgirButton>
					}
				


					<SurgirButton
						onClick={onClick}
						fontFamily='"Str-Microtext-Bold", sans-serif'
						colorText={'#EC0000'}
						variant={'outlined'}
						marginTop='0px'
						marginBottom='0px'
						border='none'
					>
						Ver más
					</SurgirButton>
				</div>
				
			</div>

		</div>

	);
}

export default SurgirCardPromotions;