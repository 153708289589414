import React from "react";
import {
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap";
import First from "./../../assets/images/Icons_descargar.svg";
import "./ListOfNotaries";
import { createLink } from "utils/urls";

const ListOfNotaries: React.FC<any> = () => {
  return (
    <div>
      <div className="fq-header">
				<Container>
				<span className="fq-title">Listado de notarios</span>
				</Container>
			</div>
      <Container>
      <Row style={{ margin: "40px 0px"}}>
        <Col md={3}>
          <p className="Columna">Documentos descargables</p>
        </Col>
        <Col md={9}>
          <Card body style={{ paddingLeft: 25 }}>
            <Row>
              <Col md={9}>Listado de Notarios</Col>
              <Col md={3}>
                <a
                  className="Descarga"
                  style={{ textDecoration: "none", color: "#767676" }}
                  rel="noopener noreferrer"
                  download={"listado_de_notario"}
                  onClick={() => createLink(process.env.REACT_APP_LISTADO_DE_NOTARIOS ?? "", "_blank")} 
                >
                   <img style={{ marginRight: 5 }} src={First} alt="descargar" />
                   Descargar
                </a>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      </Container>
    </div>
  );
}

export default ListOfNotaries;
