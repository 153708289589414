import SurgirAccordion from "components/ui/SurgirAccordion/SurgirAccordion";
import SurgirCarousel from "components/ui/SurgirCarousel/SurgirCarousel";
import SurgirPrincipalTitle from "components/ui/SurgirPrincipalTitle/SurgirPrincipalTitle";
import SurgirSlideSantander from "components/ui/SurgirSlideSantander/SurgirSlideSantander";
import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import LinkRoutes from "constants/LinkRoutes";
import { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { goTopTab } from "utils/functions";
import { ReactComponent as Renovación } from '../../assets/images/dpf/Icon-CB030-financing.svg';
import { ReactComponent as TreaDpf } from '../../assets/images/dpf/icono trea.svg';
import SurgirButton from "../../components/ui/SurgirButton/SurgirButton";
import SurgirDownloadIcon from "../../components/ui/SurgirDownloadIcon/SurgirDownloadIcon";
import { OPERATIONS, frequentQuestionsPART1 } from './FixedDeposit.fixture';
import "./FixedDeposit.scss";
import SurgirBanner from "components/ui/SurgirBanner/SurgirBanner";
import { createLink } from "utils/urls";

export default function Dpf() {

  const navigate = useNavigate();
  const [keyTabInfo, setKeyTabInfo] = useState("Requisitos");

  const requestCredit = () => {
    navigate("/abre-tu-deposito-a-plazo-fijo");
    window.scrollTo(0, 0);
  }

  const redirectFormDPF = () => {
    navigate("/abre-tu-deposito-a-plazo-fijo");
    window.scrollTo(0, 0);
  }

  const redirectSimulador = () => {
    createLink(process.env.REACT_APP_DPF_SIMULADOR ?? "", "_blank");
  }

  const redirectPDFSolicitarApertura = () => {
    createLink(process.env.REACT_APP_DPF_SOLICITUD_APERTURA ?? "", "_blank");
  }

  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches != matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener("resize", listener)
    }, [matches, query]);

    return matches;
  }

  const goTabDocument = () => {
    goTopTab('section-tab-dpf');
    setKeyTabInfo('Documentos');
  }

  const mobile = useMediaQuery('(max-width: 575px)');
  const isTabletSm = useMediaQuery('(min-width: 576px) and (max-width: 767px)');
  const mobileTab = useMediaQuery('(max-width: 767px)');


  const [faqVisibleDPF, setFaqVisibleDPF] = useState(false);

  const frequentQuestionsPART2 = [
    {
      title: '¿Qué necesito para abrir mi Depósito a Plazo Fijo en SURGIR?',
      body: <div style={{ lineHeight: "32px" }}>
      <strong>Persona Natural</strong>
      <br />
      <ul>
        <li>
          Presentar tu Documento de Identidad y el depósito mínimo de apertura de S/ 500.00 o US$ 500.00.
        </li>
      </ul>
      <strong>Persona Jurídica</strong>
      <ul>
        <li>
          Copia legalizada de la Escritura Pública sobre constitución social de la empresa.
        </li>
        <li>
           Original de la Copia literal de la partida registral de la empresa o vigencia de poderes (con una antigüedad no mayor a 30 días)
        </li>
        <li>
           Copia simple de la Ficha RUC.
        </li>
        <li>
          Copia simple de los documentos de identidad de los representantes.
        </li>
        <li>
          Otros documentos de acuerdo al tipo de empresa.
        </li>
      </ul>
    </div>
    },
    {
      title: '¿Cuáles son las ventajas de mi Depósito a Plazo Fijo?',
      body: <div style={{ lineHeight: "32px" }}>
      <ul>
        <li>
          Rentabilidad asegurada con las tasas que te brindamos.
        </li>
        <li>
          Cuidamos tus ahorros en un lugar seguro.
        </li>
        <li>
          Podrás elegir 2 modalidades para el abono de tus intereses:
  
        </li>
        <div style={{ marginLeft: '20px' }}>
          •  Al vencimiento con capitalización.
  
          <br />
          •  Periódico: mensual, bimestral, trimestral, semestral, anual o al vencimiento sin capitalización, abonándose en tu Cuenta de Ahorros de SURGIR.
        </div>
        <li>
  
          Aperturar tu Depósito a Plazo Fijo en la moneda que prefieras: soles o dólares.
        </li>
        <li>
           Costo cero en mantenimiento y comisiones para que disfrutes más de la rentabilidad de tu dinero.
        </li>
      </ul>
    </div>
    },
    {
      title: '¿Puedo cancelar mi Depósito a Plazo Fijo antes del vencimiento del plazo pactado?',
      body: <div style={{ lineHeight: "32px" }}>
  
      Podrás revisar las tasas de pre-cancelación en los siguientes documentos:
      <br />
      <span>• </span><span role="button" className="span-surgir" onClick={goTabDocument}>Cartilla Información</span>.<br />
      <span>• </span><span role="button" className="span-surgir" onClick={goTabDocument}>Tarifario</span> de productos pasivos.<br />
      <span>• </span><span role="button" className="span-surgir" onClick={ () => {window.open(LinkRoutes.promotionHistory, '_blank')}}>Términos y condiciones</span> de la campaña que deseas participar.
    </div>
    },
    {
      title: '¿Qué sucede con mi Depósito a Plazo Fijo cuando llega la fecha de vencimiento?',
      body: <p>Te lo renovamos automáticamente de acuerdo a la tasa vigente de nuestro tarifario en la fecha de vencimiento. No aplica tarifarios de Campaña del producto mencionado. Si se desea acceder a la tasa de campaña, se deberá abrir un nuevo Depósito a Plazo Fijo durante el plazo de vigencia de la campaña. </p>
    },
    {
      title: '¿El Depósito a Plazo Fijo se encuentra cubierto por el Fondo de Seguro Depósito?',
      body: <p>
      SURGIR deberá efectuar el pago de las primas que fije la Superintendencia de Banca, Seguros y AFP (SBS), durante veinticuatro (24) meses sucesivos para que sus operaciones se encuentren respaldadas por el Fondo y dar cumplimiento a lo dispuesto en el Artículo N° 3 “Nuevos Miembros” de la Resolución SBS N° 0657 - 99 y sus modificatorias. Por lo tanto, los Productos Pasivos en SURGIR estarán protegidos por el Fondo de seguro de depósito dentro del periodo de veinticuatro (24) meses de acuerdo con lo establecido en la normativa vigente aplicable. Más información en <a href="/."
          rel="noopener noreferrer"
          style={{ color: "#257fa4" }}>www.surgir.com.pe</a> o en <a href="https://fsd.org.pe/"
            rel="noopener noreferrer"
            style={{ color: "#257fa4" }}>www.fsd.org.pe</a>.</p>
    },
    {
      title: '¿Qué es la TREA?',
      body: <p> La TREA es la Tasa de Rendimiento Efectiva Anual y te permite saber cuánto ganarás efectivamente por el dinero que deposites al vencimiento del plazo, considerando todos los cargos por comisiones y gastos, bajo el supuesto de cumplimiento de todas las condiciones pactadas. </p>
    },
    {
      title: '¿Cuál es el procedimiento aplicable en el caso de fallecimiento del titular de la cuenta?  ',
      body: <div style={{ lineHeight: "32px" }}>
      <strong>Por Sucesión Testada:</strong>
      <br /> • Partida de defunción en original o copia.
      <br /> • Copia legalizada notarial del testamento
      <br />• Copia literal original del Testamento inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.
      <br />• Copia del documento de identidad de cada solicitante. En caso de menores de edad, deberá proporcionar la Partida de nacimiento y el documento de identidad.
      <br />
      <strong>Por Sucesión Intestada:</strong>
      <br />
      <strong>Notarial:</strong>
      <br />• Copia legalizada del Acta de la Sucesión Intestada (que declara herederos del causante) o la protocolización notarial de Declaratoria de Herederos.
      <br />• Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.
      <br />• Copia del documento de identidad de cada solicitante. En caso de menores de edad, deberá proporcionar la Partida de nacimiento y el documento de identidad.
      <br />
      <strong>Judicial: </strong>
      <br />• Copia certificada por el Poder Judicial de la Resolución de Sucesión Intestada emitida por el juzgado respectivo (que declare los herederos del causante).
      <br />• Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.
      <br />
  
      <strong> En el supuesto de que exista heredero menor de edad existen dos opciones presentar una de ellas dependiendo su caso: </strong>
      <br />• Copia certificada por el Poder Judicial de la Resolución de División y Partición y de la Resolución que la declare consentida.
      <br />• Copia certificada por el Poder Judicial de la Resolución Judicial que autoriza a disponer los fondos del menor, y de la Resolución que la declare consentida, para los casos que se requiera disponer de los fondos que le corresponda al menor.
      <br />
  
  
      <strong>  En el caso que uno de los herederos declarados haya fallecido: </strong>
      <br />• Copia literal original de la declaratoria de herederos inscrito en Registros Públicos, con una antigüedad no mayor a 15 días emitida por SUNARP.
      <br />
  
      <strong>En el supuesto que uno de los solicitantes actúe mediante poder: </strong>
      <br />• Copia literal original de otorgamiento de poder o vigencia de poder, con una antigüedad no mayor a 15 días emitida por SUNARP.
      <br />
      <strong>En el caso que el fallecido era casado con separación de bienes: </strong>
      <br />• Copia literal original de la separación de patrimonios si la hubiese, con una antigüedad no mayor a 15 días emitida por SUNARP.
      <br />
    </div>
    },
    {
      title: '¿Cuál es el procedimiento de cancelación de la cuenta? ',
      body: <div>
        El cliente deberá solicitar la cancelación de su cuenta contactándose con su Ejecutivo de Cuenta, acercándose a la Oficina Especial Compartida o comunicándose a través del buzón atencionahorros@surgir.com.pe, presentando los siguientes documentos:
                        <div style={{ lineHeight: "32px" }}>
                          <br />• La <span role="button" className="span-surgir" onClick={goTabDocument}>Carta de Instrucción</span> llenada dirigida a la Financiera y firmada por los titulares de la cuenta.
                          <br />• Adjuntar la copia de DOI (documento oficial de identidad) del (los) titular(es).
                        </div>
                        <br></br>
                        <strong>Importante: </strong>Si deseas transferir tu dinero a otro banco deberás indicar el número y código interbancario de la cuenta de ahorros de recepción de dinero, esta cuenta deberá tener la misma titularidad del Depósito a Plazo Fijo en SURGIR.
  
                        <br />En caso de enviar la carta al buzón, colocar como asunto: Cancelación de Depósito a Plazo Fijo y/o Cuenta de Ahorros.
                        <br />
                        <br />
                        Para más información contactarse con su Ejecutivo de Cuenta.
                        <br></br>
      </div>
    },
    {
      title: '¿Dónde puedo solicitar más información para abrir mi cuenta?  ',
      body: <div>
        <li>Solicita un <strong> Depósito a Plazo Fijo </strong> en nuestro <a className="linkturquesa"
                          style={{ color: "#257FA4" }} target="_blank"
                          href="/abre-tu-deposito-a-plazo-fijo">formulario</a> y nos comunicaremos contigo.
                        </li>
                        <li><strong>Oficina Especial Compartida: </strong> Cl. Amador Merino Reina 411, San Isidro.
                        </li>
                        {/* <li><strong>Central telefónica:</strong> <a href="tel:(01) 480 0123"
                          rel="noopener noreferrer"
                          style={{ color: "#257fa4" }}>(01) 480 0123.</a>
                        </li> */}
      </div>
    },
  ]

  const TABS_FIXED_DEPOSIT = [
    {
      title: 'Requisitos',
      body: (
        <div className="tab-requeriments">
          <span>Persona Natural</span>
          <ul>
            <li>Presentar original y copia de tu Documento Oficial de Identidad (DOI).</li>
          </ul>
          <div>
            <p>En caso actúe por medio de un representante o apoderado deberá proporcionarse:</p>
          </div>
          <ul>
            <li>Vigencia de poder (no mayor a 30 días) del apoderado.</li>
            <li>Copia del Documento de Identidad del apoderado.</li>
          </ul>
          <span >Persona Jurídica</span>
          <ul>
            <li>	Ficha RUC actualizada.</li>
            <li>	Copia del Testimonio de constitución de la Persona Jurídica u otro documento que acredite la creación de la sociedad.</li>
            <li>	Copia Literal o Partida Electrónica de la Persona Jurídica o Vigencia de poder del apoderado o representante legal no mayor a 30 días u otro documento que acredite sus poderes.</li>
            <li>	Copia del Documento de Identidad del apoderado o representante legal.</li>
            <li>	Otros documentos de corresponder.</li>
          </ul>

          <strong>Importante: De acuerdo con el tipo de sociedad, el Ejecutivo de Cuenta le solicitará documentación adicional.</strong>

        </div>
      )
    },
    {
      title: 'Documentos',
      body: (
        <div className="tab-documents">
          <SurgirDownloadIcon
            text={'Términos y condiciones - ¡Tus ahorros crecen en verano! (PDF / 560 KB)'}
            href={process.env.REACT_APP_PROM_TERMINOS_TASA_VERANO_2025}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Documentos Contractuales - Cartilla PN (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_CONTRACTUAL_CARTILLA}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Documentos Contractuales - Cartilla PJ (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_CONTRACTUAL_CARTILLA_PJ}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Documentos Contractuales - Contrato (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_CONTRACTUAL_CONTRATO}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Tarifario de tasas (PDF/560 KB) '}
            href={process.env.REACT_APP_DPF_TARIFARIO_TASAS}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Tarifario de comisiones (PDF/560 KB) '}
            href={process.env.REACT_APP_DPF_TARIFARIO_COMISIONES}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Fórmulas y ejemplos explicativos (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_FORMULAS_EJEMPLOS}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Documento o sección de Beneficios, Riesgos y Condiciones del Producto (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_BENEFICIOS_CONDICIONES}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Solicitud de Apertura (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_SOLICITUD_APERTURA}
            underline={true}
          />
          <SurgirDownloadIcon
            text={'Carta de Instrucción (PDF/560 KB)'}
            href={process.env.REACT_APP_DPF_CARTA_INSTRUCCION}
            underline={true}
          />
        </div>
      )
    },

    {
      title: 'Preguntas frecuentes',
      body: (
        <div className="tab-faq">
          <SurgirAccordion sections={frequentQuestionsPART1}/>
          <Accordion>
            {
              !faqVisibleDPF ? (
                <div className="section-information__tabs__link">
                  <button className="section-information__tabs__link__body" onClick={() => setFaqVisibleDPF(true)} >
                    <h6 >Ver más preguntas</h6>
                    <span>
                      <img src="icons/safe/chevron-down-sm.svg" alt="super seguro" />
                    </span>
                  </button>
                </div>
              ) : (
                <div className="mt-2">
                  <SurgirAccordion sections={frequentQuestionsPART2}/>

                  <div className="section-information__tabs__link">
                      <button className="section-information__tabs__link__body" onClick={() => setFaqVisibleDPF(false)} >
                        <h6 >Ver menos preguntas</h6>
                        <span>
                          <img src="icons/safe/chevron-up-sm.svg" alt="super seguro" />
                        </span>
                      </button>
                    </div>

                </div>
              )
            }

          </Accordion>
        </div>
      )
    },
    {
      title: 'Solicítalo aquí',
      body: (
        <div className="tab-solicitalo">
          <p style={{marginBottom: '25px'}}>Solicita tu Depósito a Plazo Fijo a través de nuestros canales:</p>
          <div className="tab-solicitalo__cards">
            <div className="tab-solicitalo__cards__card" >
              <div className="tab-solicitalo__cards__card__icon">
                <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/solicitalo-form.svg'} />
              </div>
              <div className="tab-solicitalo__cards__card__text">
                <p >
                  Completa nuestro <span className="span-surgir"><a href={LinkRoutes.formDepositFixed} target="_blank">formulario</a></span> y nos comunicaremos contigo.
                </p>
              </div>
            </div>
            <div className="tab-solicitalo__cards__card" >
              <div className="tab-solicitalo__cards__card__icon">
                <img alt="Depósito a Plazo Fijo Flexible" src={'icons/flexible-deposit/solicitalo-point.svg'} />
              </div>
              <div className="tab-solicitalo__cards__card__text">
                <p>
                  Oficina Especial Compartida: Cl. Amador Merino Reina 411, San Isidro.
                </p>
              </div>
             
            </div>

          </div>
        </div>
      )
    },
    {
      title: 'Simulador',
      body: (
        <div className="tab-simulador">
          <p>¡Entérate cuantos intereses ganarás con nuestras súper tasas!</p>
          <div>
            <SurgirDownloadIcon
             text={'Simulador Depósito a Plazo Fijo (XLSX/560 KB)'}
             href={process.env.REACT_APP_DPF_SIMULADOR}
              underline={true}
            />
          </div>
        </div>
      )
    }
  ]



  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const benfitsFixedDeposit = [
    {
      icon: 'savings.svg',
      title: 'Rentabiliza tu dinero',
      description: 'Súper tasas de hasta 5.80% TREA y plazos desde 90 días.',
      alert: true,
      textAlert: ['5.80% TREA', '90']
    },
    {
      icon: 'clock-check.svg',
      title: 'Apertura rápida y sencilla',
      description: 'Ábrelo en donde estés, al realizar una transferencia a nuestras cuentas. Solicítalo aquí.',
      link: true,
      textLink: 'Solicítalo aquí',
      hrefLink: LinkRoutes.formDepositFixed
    },
    {
      icon: 'hands-red.svg',
      title: 'Atención personalizada',
      description: 'Nuestros Ejecutivos de Cuenta te brindarán servicio personalizado y asesoría financiera.'
    },
    {
      icon: 'santander.svg',
      title: 'Seguridad y respaldo',
      description: 'Supervisados por la SBS y con el respaldo de Santander, tus ahorros estarán seguros.',
      link: true,
      textLink: 'Santander',
      hrefLink: 'https://www.santander.com.pe/'
    },
    {
      icon: 'calendar.svg',
      title: 'Flexibilidad en tus intereses',
      description: 'Recibe el abono de tus intereses de forma periódica o al vencimiento con capitalización.'
    },
    {
      icon: 'security-verified.svg',
      title: 'Confidencialidad',
      description: 'Nuestras políticas protegen tu información en todo momento.'
    },
  ]
  return (
    <div>
      <SurgirBanner 
        title={'¡Tus ahorros crecen este verano!'}
        description={'Sube la temperatura con nuestra SÚPER TASA'}
        image={'banner-dpf.png'}
        imageFooter={'logo-santander-red.svg'}
        showButton={true}
        textButton="Solicítalo ahora"
        showFooter={true}
        backgroundColor={'#FFFFFF'}
        eventClickButton={redirectFormDPF}
        altImgBanner="Alt DPF_ Sube la temperatura con nuestras súper tasas SURGIR ahorros inversión depósito a plazo fijo Banner Producto"
      />

      <div className="section-trea">
        <Container>
          <div className="section-trea__body">
            <div className="section-trea__body__content__title-responsive">Haz crecer tus ahorros de forma segura y rentable en SURGIR</div>

            <div >
              <img className="section-trea__body__image" alt='Haz crecer tus ahorros con tu Depósito a Plazo Fijo SURGIR Ahorro' src="images/dpf/DPF seccion.png" />
            </div>
            <div className="section-trea__body__content">
              <div className="section-trea__body__content__title">Haz crecer tus ahorros de forma segura y rentable en SURGIR</div>
              <p className="section-trea__body__content__description">Abre tu Depósito a Plazo Fijo a <strong> partir de S/ 500 </strong>con nuestras súper tasas y haz que tu dinero crezca.</p>
              <br />
              <div ><TreaDpf style={{ marginRight: "10px" }} /><span className="parrafo-bold">Hasta <span className="parrafo-rojo"> 5.80% TREA </span> en soles o <span className="parrafo-rojo"> 3.00% TREA </span>en dólares.</span></div>
              <br />
              <div><Renovación style={{ marginRight: "10px" }} /><span className="parrafo-bold">Elige 2 modalidades de abono de intereses:</span></div>
              <br />
              <div className="dpf-section-information__tabs__tab-requirements" >
                <ul>
                  <li> Al vencimiento con capitalización.
                  </li>
                  <li> Periódico: mensual, bimestral, trimestral, semestral, anual o al vencimiento sin capitalización, abonándose en tu Cuenta de Ahorros de SURGIR.
                  </li>
                </ul>
              </div>

              <div className="section-trea__body__content__button "> <SurgirButton
                onClick={requestCredit}
                variant="danger"
                radius="24px"
                width={"163px"}
                height={"48px"}
                marginBottom={"0px"}
                marginTop={"20px"}
                fontFamily={"'Str-Microtext-Bold', sans-serif"}
              >
                ¡Lo quiero!
              </SurgirButton>
              </div>
            </div>


          </div>
        </Container>
      </div>

      <div className="dpf-section-benefits">
        <div>
          <SurgirPrincipalTitle text="Descubre los beneficios que tenemos para ti" />

          <div>
            <SurgirCarousel 
              items={benfitsFixedDeposit}
            />
          </div>
        </div>
      </div>

      <div>
        <SurgirSlideSantander/>
      </div>
      


      <div className="section-steps">
        <Container>
          <div className="title-dpf">
            ¡Solicita tu Depósito a Plazo Fijo fácil y rápido!
          </div>
          <div className="description-dpf">
            Solicítalo en 3 simples pasos
          </div>
          <div className="section-steps__body">

            <div className="section-steps__body__image">

              <img src="images/dpf/DPF pasos.png" alt='Solicita tu Depósito a Plazo Fijo fácil y rápido SURGIR Ahorro' />


            </div>


            <div className="section-steps__body__steppers">

              <ol>
                {
                  OPERATIONS.map((item, index) => {
                    return (
                      <li key={item.title}
                        className={"section-steps__body__steppers__item"}
                        data-content={index + 1}
                      >
                        <div className="section-steps__body__steppers__content">
                          <div>
                            <span className="section-steps__body__steppers__content__title">{item.title}</span>
                            {
                              item.url ? (
                                <SurgirButton
                                  colorText={'#ec0000'}
                                  variant="link"
                                  marginTop={'0px'}
                                  marginBottom={'4px'}
                                  border={'0px'}
                                  onClick={redirectFormDPF}

                                >
                                  {item.link}
                                </SurgirButton>
                              ) : (
                                <SurgirButton
                                  variant=""
                                  downloadUrl={process.env.REACT_APP_DPF_SOLICITUD_APERTURA}
                                  downloadStyleClass={'linkUnderline'}

                                >
                                  {item.link}
                                </SurgirButton>
                              )
                            }
                          
                            <h6 className="section-steps__body__steppers__content__description">{item.description}</h6>
                          </div>
                        </div>
                      </li>

                    )
                  })
                }
              </ol>
              <div className="section-steps__body__description" >
                <div>
                  <span className="parrafo"><span className="parrafo-gris">¡Y listo! </span>Podríamos enviarte los contratos a tu correo electrónico o a tu domicilio ¡tú eliges!</span>

                  <br />
                  <br />

                  <span className="parrafo-gris">¡Comienza a crecer tu dinero con SURGIR!</span>
                </div>
              </div>
              <div className="section-steps__body__button" >
                <SurgirButton
                  onClick={requestCredit}
                  variant="danger"
                  radius="24px"
                  width={"183px"}
                  height={"48px"}
                  marginBottom={"0px"}
                  marginTop={"20px"}
                  fontFamily={"'Str-Microtext-Bold', sans-serif"}
                >
                  Solicítalo ahora
                </SurgirButton>
              </div>
            </div>
          </div>
        </Container>
      </div>


      <div className="section-simula">
        <Container>
          <div className="section-simula__body">
            <div className="section-simula__body__content">
              <div className="section-simula__body__content__title">Simula tu Depósito a Plazo Fijo</div>
              <p className="section-simula__body__content__description">Conoce cuanto interés ganarás con nuestras súper tasas.</p>
              <br />
              <div className="section-steps__body__button" >
                <SurgirButton
                  onClick={redirectSimulador}
                  variant="danger"
                  radius="24px"
                  width={"183px"}
                  height={"48px"}
                  marginBottom={"0px"}
                  marginTop={"0px"}
                  fontFamily={"'Str-Microtext-Bold', sans-serif"}
                  border="1px solid #23779A"
                >
                  Simula aquí
                </SurgirButton>
              </div>

            </div>
            <div >
              <img className="section-simula__body__image" alt='Simula cuando interés ganas con tu Depósito a Plazo Fijo SURGIR Ahorro' src="images/dpf/DPF simulador.png" />
            </div>
          </div>
        </Container>
      </div>

      <div className="dpf-section-information" id="section-tab-dpf">
        <Container>
          <div className="title-dpf" style={{ marginBottom: 30 }}>
            Conoce más sobre tu Depósito a Plazo Fijo
          </div>

          <div className="dpf-section-information__tabs">

            {
              !mobileTab ? <SurgirTabs tabs={TABS_FIXED_DEPOSIT}></SurgirTabs> :
                <div className='container-acordion-responsive'>
                  <SurgirAccordion
                    sections={TABS_FIXED_DEPOSIT}
                  />
                </div>
            }

          </div>

        </Container>
      </div>

    </div>
  );

}