export const datacheck = [
  {
    id: 'chkAccordance',
    text: "Mediante el mensaje enviado manifiesto mi conformidad del reclamo o queja ingresado.",
    textWarning: "Pendiente dar conformidad del reclamo o queja ingresado.",
  },
  {
    id: 'chkPolitics',
    text: "He leído y acepto la ",
    textWarning:
      "Pendiente aceptar la “Política de tratamiento de protección de datos personales”.",
    textUrl: "Política de tratamiento de protección de datos personales.",
    directUrl:
      "/proteccion-de-datos",
  },
];

export const datacheckRequerimiento = [
  {
    id: 'chkAccordance',
    text: "Mediante el mensaje enviado manifiesto mi conformidad del requerimiento ingresado.",
    textWarning: "Pendiente dar conformidad del requerimiento ingresado.",
  },
  {
    id: 'chkPolitics',
    text: "He leído y acepto la ",
    textWarning:
      "Pendiente aceptar la “Política de tratamiento de protección de datos personales”.",
    textUrl: "Política de tratamiento de protección de datos personales.",
    directUrl:
      "/proteccion-de-datos",
  },
];


export const  typeComplaints = [
  { id: 1, name: "Tengo un reclamo o queja" },
  { id: 2, name: "Tengo un requerimiento" },
];

export const titleRadioClaim ="¿Cómo deseas recibir la respuesta a tu reclamo o queja?";
export const titleRadioSurgirClient ="¿Ya eres cliente de SURGIR?";
export const titleRadioRequirements ="¿Cómo deseas recibir la respuesta a tu requerimiento?";
export const titleRadioAdress ="¿Tu dirección de contacto es de Perú?";
export const subTitleClaim ="Compártenos tu reclamo o queja";
export const subTitleRequirements ="Compártenos tu requerimiento";
export const subTitleDataStepTwo ="Bríndanos tus datos personales";
export const optionalInputMessage ="Solo en caso de Persona Jurídica";
export const descripTooltipClaim = "Puede ser un voucher de pago o documentación que consideres que tenga relación con tu reclamo o queja."
export const descripTooltipRequirement = "Puede ser un voucher de pago o documentación que consideres que tenga relación con tu requerimiento."
export const GotoFrequentQuestionsText1 ="Ir a Remisión de Información";
export const GotoFrequentQuestionsText2 ="Ir a Preguntas Frecuentes";
export const GotoFrequentQuestionsTitle1 ="Información de tu crédito";
export const GotoFrequentQuestionsTitle2 ="Resolvemos tus dudas en un instante";
export const GotoFrequentQuestionsSubTitle1 ="Solicita documentos e información que necesites relacionada a tu crédito.";
export const GotoFrequentQuestionsSubTitle2 ="Ingresa a la página de Preguntas Frecuentes y encuentra respuestas rápidamente.";
export const ContactData ="Bríndanos tus datos de contacto";

