import SurgirItemListWithIcon from "components/ui/SurgirItemListWithIcon/SurgirItemListWithIcon";
import DownloadIconTab from '../../assets/images/Icons_descargar.svg'
import { Container } from "react-bootstrap";
import './DataProtection.scss'

const DataProtection: React.FC = () => {
	return (
		<div className="data-protection">
			<div className="fq-header">
				<Container>
				<span className="fq-title">¿Cómo protegemos tus datos?</span>
				</Container>
				
			</div>


			<Container>
			<div style={{marginTop: "40px"}}>
				<p>Creemos que es importante que entiendas de una manera simple. ¿Cómo trataremos tu información personal que nos brindaste?</p>
				<p>Por eso, nos hemos propuesto hacerlo de una manera directa y con transparencia para que te sientas informado, seguro y atendido. A continuación, puedes visualizar y descargar nuestras políticas:</p>
			</div>

			<div style={{display: "flex", flexDirection: "column", gap: "24px" , margin: "40px 0px"}}>
				<div className="card-style-transparence"  >
					<div style={{ width: "100%" }} >
						Conoce nuestra Política de Privacidad
					</div>
					<div style={{ width: "40%" }}>
						<SurgirItemListWithIcon
							icon={DownloadIconTab} 
							document={process.env.REACT_APP_POLITICA_PRIVACIDAD}
							name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} 
							downloadName={"Terminos"}
						></SurgirItemListWithIcon>
					</div>
				</div>
				<div className="card-style-transparence"  >
					<div style={{ width: "100%" }} >
						Conoce nuestra Política de Cookies
					</div>
					<div style={{ width: "40%" }}>
						<SurgirItemListWithIcon
							icon={DownloadIconTab} 
							document={process.env.REACT_APP_POLITICA_COOKIE}
							name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} 
							downloadName={"Terminos"}
						></SurgirItemListWithIcon>
					</div>
				</div>
				<div className="card-style-transparence"  >
					<div style={{ width: "100%" }} >
						Conoce nuestro informativo de videovigilancia
					</div>
					<div style={{ width: "40%" }}>
						<SurgirItemListWithIcon
							icon={DownloadIconTab} 
							document={process.env.REACT_APP_INFORMATIVO_VIDEOVIGILANCIA}
							name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} 
							downloadName={"Terminos"}
						></SurgirItemListWithIcon>
					</div>
				</div>

				<div className="card-style-transparence"  >
					<div style={{ width: "100%" }} >
						Conoce el inventario de encargados de tratamiento de datos y TID
					</div>
					<div style={{ width: "40%" }}>
						<SurgirItemListWithIcon
							icon={DownloadIconTab} 
							document={process.env.REACT_APP_INVENTARIO_TRATAMIENTO_DATOS_TID}
							name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} 
							downloadName={"Terminos"}
						></SurgirItemListWithIcon>
					</div>
				</div>

				<div className="card-style-transparence"  >
					<div style={{ width: "100%" }} >
						Aquí puedes descargar la solicitud para ejercer tus derechos ARCO+
					</div>
					<div style={{ width: "40%" }}>
						<SurgirItemListWithIcon
							icon={DownloadIconTab} 
							document={process.env.REACT_APP_SOLICITUD_ATENCION_DERECHOS_ARCO}
							name={<span className="linkrojo" style={{ color: '#EC0000' }}> Descargar</span>} 
							downloadName={"Terminos"}
						></SurgirItemListWithIcon>
					</div>
				</div>

			</div>

			<div style={{marginBottom: "60px"}}>
				<p>Antes de despedirnos, ponemos a tu disposición a nuestro Oficial de Protección de datos personales para que realices consultas y/o sugerencias a través del siguiente buzón: <span>protecciondedatos@surgir.com.pe</span></p>
			</div>

			</Container>

		
		</div>
	)
}

export default DataProtection;