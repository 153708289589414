import {
  Tab,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import "./FrequencyQuestions.scss";
import { aboutInsurance, aboutLoans } from "./FrequencyQuestions.fixture";
import SurgirAccordion from "components/ui/SurgirAccordion/SurgirAccordion";

function FrequencyQuestions() {
  return (
    <div>    
      <div className="fq-header wrapper">
        <span className="fq-title"> Preguntas Frecuentes </span>
        <span className="fq-sub-title" style={{ marginLeft: 14 }}>
          Encuentra la respuesta a tus dudas aquí.
        </span>
      </div>
      <div style={{ marginBottom: 50 }}>
        <Tab.Container defaultActiveKey="first">
          <Row
            className="wrapper"
            style={{ padding: 30, paddingLeft: "9%", paddingRight: "9%" }}
          >
            <Col sm={4}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first"> Sobre Préstamos </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second"> Sobre Seguros</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                 <div className="mt-2">
                   <SurgirAccordion sections={aboutLoans}/>
                 </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="mt-2">
                    <SurgirAccordion sections={aboutInsurance}/>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
}

export default FrequencyQuestions;
