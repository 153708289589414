
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import SurgirDownloadIcon from "../../components/ui/SurgirDownloadIcon/SurgirDownloadIcon";
import { PLANS, TABLE_PLANS, OPERATIONS, COVERAGE, frequentQuestionsPART1, frequentQuestionsPART2, whatWeDoNotCover } from "./Safe.fixture";

import "./Safe.scss";

import {
  Container,
  Table,
  Accordion,
} from "react-bootstrap";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "../../assets/swiper/swiper.css";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import SurgirBanner from "components/ui/SurgirBanner/SurgirBanner";
import SurgirTabs from "components/ui/SurgirTabs/SurgirTabs";
import SurgirAccordion from "components/ui/SurgirAccordion/SurgirAccordion";
import { createLink } from "utils/urls";
import SurgirSectionOperation from "components/ui/SurgirSectionOperation/SurgirSectionOperation";
import SurgirCardsProductCredit from "components/ui/SurgirCardsProductCredit/SurgirCardsProductCredit";
import SurgirSectionContact from "components/ui/SurgirSectionContact/SurgirSectionContact";


const titleSectionPlans = "Elige tu plan ideal";

const Safe: React.FC = () => {

  const [tablePlanVisible, setTablePlanVisible] = useState(false);
  const [showCardsCoverage, setShowCardsCoverage] = useState(6);


  const useMediaQuery = (query: any) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener("resize", listener)
    }, [matches, query]);

    return matches;
  }

  const mobile = useMediaQuery('(max-width: 575px)');
  const isTabletSm = useMediaQuery('(min-width: 576px) and (max-width: 767px)');

  const styleCardsPlans = (index: any) => {

    if (index === 0) {
      return 'section-plans__cards__first';
    }

    return index === 1 ? 'section-plans__cards__second' : 'section-plans__cards__thirth'
  }

  const [faqVisible, setFaqVisable] = useState(false)

  const TABS_SAFE = [
    {
      title: 'Requisitos',
      body: (
        <div className="section-information-safe__tabs__tab-requirements">
          <ul>
            <li>Contar con una edad mínima de 18 años y un máximo de 68 años con 364 días.</li>
            <li>Tener el crédito vinculado vigente y estar al día en el pago de sus cuotas.</li>
          </ul>
        </div>
      )
    },
    {
      title: 'Lo que no cubrimos',
      body: (
        <div className="accordion-what-we-do-not__tabs">
          <SurgirAccordion sections={whatWeDoNotCover}/>
        </div>
      )
    },
    {
      title: 'Preguntas frecuentes',
      body: (
        <div>
          <Accordion>
            <SurgirAccordion sections={frequentQuestionsPART1}/>

            {
              !faqVisible ? (
                <div className="section-information-safe__tabs__link">
                  <div className="section-information-safe__tabs__link__body" onClick={() => setFaqVisable(true)} >
                    <h6  >Ver más preguntas</h6>
                    <span>
                      <img src="icons/safe/chevron-down-sm.svg" alt="super seguro" />
                    </span>
                  </div>

                </div>
              ) : (
                <div className="mt-2">
                  <SurgirAccordion sections={frequentQuestionsPART2}/>
                  <div className="section-information-safe__tabs__link">
                    <div className="section-information-safe__tabs__link__body" onClick={() => setFaqVisable(false)} >
                      <h6 >Ver menos preguntas</h6>
                      <span>
                        <img src="icons/safe/chevron-up-sm.svg" alt="super seguro" />
                      </span>
                    </div>

                  </div>

                </div>
              )
            }

          </Accordion>
        </div>
      )
    },
    {
      title: 'Documentos',
      body: (
        <div>
          <div>
            <SurgirDownloadIcon
              text={'Certificado de Super Seguro (PDF/560 KB)'}
              href={process.env.REACT_APP_ARCHIVO_DE_SUPERSEGURO_CERTIFICADO}
            />
          </div>

          <div>
            <SurgirDownloadIcon
              text={'Beneficios riesgos y condiciones - Operaciones Activas (PDF/265 KB)'}
              href={process.env.REACT_APP_INFORMACION_REFERIDA_DEL_PRODUCTO}
            />
          </div>
        </div>

      )
    }
  ]

  const [animation, setAnimation] = useState('open')

  const isDesktop = useMediaQuery('(min-width: 1200px)');


  return (
    <div>

      <SurgirBanner
        showButton={false}
        textButton="Solicítalo ahora"
        title={'Super Seguro'}
        description={'Protege a tu familia y negocio con un seguro a tu alcance'}
        image={'banner-super-seguro.png'}
        showFooter={true}
        imageFooter={'logo-mapfre.svg'}
        backgroundColor={'#F6FAFC'}
      />

      <div className="section-benefits">
        <Container>
          <div className="title-section">
            Conoce los beneficios del Super Seguro
          </div>

        </Container>

        <Container>
          <div className="section-benefits__cards">

            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={0}
              slidesPerView={3}
              navigation={isDesktop ? true : false}
              pagination={{ clickable: true }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                576: {
                  slidesPerView: 2,
                  spaceBetween: 0
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 0
                },
                962: {
                  slidesPerView: 3,
                  spaceBetween: 0
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 0
                }
              }}
            >
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/safe/benefits-asistencia.svg" alt="super seguro" />
                    <p>Asistencia médica telefónica</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/safe/benefits-asistencia-especializada.svg" alt="super seguro" />
                    <p>Asistencia especializada para tu casa o negocio</p>
                  </div>

                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/safe/benefits-familia.svg" alt="super seguro" />
                    <p>Protección para tu familia</p>
                  </div>
                </div></SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/safe/benefits-precios-escalonados.svg" alt="super seguro" />
                    <p>Precios escalonados a tu elección</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="section-benefits__cards__card">
                  <div>
                    <img src="images/safe/benefits-cuota-credito.svg" alt="super seguro" />
                    <p>Págalo junto con la cuota de tu crédito</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

          </div>

        </Container>

      </div>

      <div className="section-toppings">
        <div className="title-section" style={{ marginBottom: '45px' }}>
          ¿En qué te cubre nuestro Super Seguro?
        </div>

        <Container>
          <div className="section-toppings__cards">
            {
              COVERAGE.map((coverage, index) => {
                return (
                  index < showCardsCoverage &&
                  <div className="section-toppings__cards__card">
                    <div className="section-toppings__cards__card__icon">
                      <img src={`images/safe/coverage/${coverage.icon}.svg`} alt="super seguro" />
                    </div>

                    <div className="section-toppings__cards__card__content">
                      <h5>{coverage.title}</h5>
                      <p>{coverage.description}</p>
                    </div>

                  </div>
                )
              })
            }
          </div>

          <div className="section-plans__link" style={{ marginTop: '28px' }}>

            {
              showCardsCoverage === 6 ? (
                <div className="section-plans__link__body" onClick={() => setShowCardsCoverage(COVERAGE.length)} >
                  <h6>Mostrar todo</h6>
                  <span>
                    <img src="icons/safe/chevron-down-sm.svg" alt="super seguro" />
                  </span>
                </div>
              ) : (
                <div className="section-plans__link__body" onClick={() => setShowCardsCoverage(6)} >
                  <h6>Mostrar Menos</h6>
                  <span>
                    <img src="icons/safe/chevron-up-sm.svg" alt="super seguro" />
                  </span>
                </div>
              )

            }

          </div>

          <div className="section-toppings__footer">
            Conoce el detalle de cada una de nuestras <span>
              <a
                rel="noopener noreferrer"
                download={"Contrato_de_crédito"}
                onClick={() => createLink(process.env.REACT_APP_ARCHIVO_DE_SUPERSEGURO_CERTIFICADO ?? "", "_blank")}>
                coberturas
              </a>
            </span>.
          </div>

        </Container>


      </div>

      <div className="section-plans">

        <Container  >
          {
            !tablePlanVisible || mobile ? (
              <div className={`Modal ${animation}`}>
                <div className="title-section">
                  {titleSectionPlans}
                </div>

                <div className="section-plans__cards">
                  {
                    PLANS.map((plan, index) => {

                      return (

                        <div key={plan.title} className={classNames
                          ("section-plans__cards__card",
                            { 'section-plans__cards__first': index === 0 },
                            { 'section-plans__cards__second': index === 1 },
                            { 'section-plans__cards__thirth': index === 2 },
                          )
                        }>
                          <h4 className={
                            classNames(
                              "section-plans__cards__card__title",
                              { 'section-plans__cards__first': index === 0 },
                              { 'section-plans__cards__second': index === 1 },
                              { 'section-plans__cards__thirth': index === 2 },
                            )
                          }
                          >
                            {plan.title}
                          </h4>
                          <h6 className="section-plans__cards__card__text" >{plan.amount} <span>{plan.description}</span></h6>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ) : (
              <div></div>
            )


          }

          {
            tablePlanVisible ? (
              <div className={`Modal ${animation}`}>
                <div className="section-plans__table">
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: '0px' }}>
                          <div className="section-plans__table__head__title">
                            {titleSectionPlans}
                          </div>
                        </th>

                        {
                          PLANS.map((plan, index) => {

                            return (

                              <th key={plan.title}>
                                <div className={
                                  classNames(
                                    "section-plans__cards__card",
                                    styleCardsPlans(index)
                                  )
                                }
                                  style={{ padding: '7px' }}
                                >
                                  <h5 className={
                                    classNames(
                                      "section-plans__cards__card__title",
                                      styleCardsPlans(index)
                                    )
                                  }
                                  >
                                    {plan.title}
                                  </h5>
                                  <h6 className="section-plans__cards__card__text" >{plan.amount} </h6>
                                  <span className="section-plans__cards__card__des" >{plan.description}</span>
                                </div>
                              </th>

                            )
                          })
                        }

                      </tr>
                    </thead>
                    <tbody>

                      {
                        TABLE_PLANS.map((plan) => {
                          return (
                            <>

                              {
                                mobile ? (
                                  <tr>
                                    <td style={{ paddingBottom: '32px !important' }} colSpan={2}>
                                      <div className="section-plans__table__body__title" style={{ alignItems: 'flex-start' }}>
                                        <img src={`images/safe/${plan.icon}.svg`} alt="super seguro" />
                                        <span>{plan.titlePlan}</span>
                                      </div>
                                    </td>
                                  </tr>
                                ) : (null)

                              }
                              <tr key={plan.icon}>
                                <td style={{ paddingLeft: '0px', display: mobile ? 'none' : '' }}>
                                  <div className="section-plans__table__body__title">
                                    <img src={`images/safe/${plan.icon}.svg`} alt="super seguro" />
                                    <span>{plan.titlePlan}</span>
                                  </div>
                                </td>


                                {
                                  plan.values.map((value, index) => {
                                    return (
                                      <td key={value.id}>
                                        <div
                                          className={
                                            classNames(
                                              "section-plans__table__body__content",
                                              { 'section-plans__table__body__content__first': index === 0 },
                                              { 'section-plans__table__body__content__second': index === 1 },
                                              { 'section-plans__table__body__content__thirth': index === 2 },
                                            )}
                                        >
                                          <div className="section-plans__table__body__content__title">{value.title}</div>
                                          <div>
                                            <span >{value.amount}</span>
                                          </div>
                                          {
                                            (value.description) && <div><span>por día</span></div>
                                          }
                                        </div>
                                      </td>
                                    )

                                  })
                                }

                              </tr>

                            </>

                          )
                        })
                      }
                    </tbody>
                  </Table>

                </div>

                <div className="section-plans__conditions">
                  <div>* Máximo 1 vez por año.</div>
                  <div>** La cobertura por renta hospitalaria, siempre y cuando corresponda, se brinda por un periodo máximo de 15 días por año.</div>
                  <div>*** Los planes 1, 2 y 3 estuvieron vigentes hasta el 15 de Setiembre del 2023. Los cambios en cobertura y costos no afectara a los planes adquiridos antes de esa fecha.</div>
                </div>

                <div className="section-plans__link">
                  <div className="section-plans__link__body" onClick={() => setTablePlanVisible(false)} >
                    <h6>Ocultar comparación</h6>
                    <span>
                      <img src="icons/safe/chevron-up-sm.svg" alt="super seguro" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="section-plans__link">
                <div className="section-plans__link__body" onClick={() => setTablePlanVisible(true)} >
                  <h6>Ver comparación</h6>
                  <span>
                    <img src="icons/safe/chevron-down-sm.svg" alt="super seguro" />
                  </span>
                </div>
              </div>
            )
          }

        </Container>
      </div>
        
      <div>
        <SurgirSectionOperation 
          image={'operation-safe'} 
          ariaLabel={'super seguro'}
          steppers={OPERATIONS}
        />
      </div>

      <SurgirCardsProductCredit
        description={'Obtén tu Super Seguro al solicitar uno de nuestros créditos'}
      />

      <div className="section-information-safe">
        <Container>
          <div className="title-section">
            Entérate más sobre tu Super Seguro
          </div>

          {
            mobile || isTabletSm ?

              <div className="section-information-safe__tabs-responsive">
                <SurgirAccordion 
                  sections={TABS_SAFE} 
                />
              </div>
              :
              <div className="section-information-safe__tabs">
                <SurgirTabs tabs={TABS_SAFE} />
                {/* <Tabs
                  defaultActiveKey="Requisitos"
                  id="tabs"
                  style={{ marginBottom: "32px" }}
                >

                  {
                    TABS_SAFE.map(item => {
                      return (
                        <Tab key={item.title} eventKey={item.title} title={item.title}>
                          {item.body}
                        </Tab>
                      )

                    })
                  }
                </Tabs> */}

              </div>
          }

        </Container>
      </div>
      
      <SurgirSectionContact title={'¿Necesitas usar tu Super Seguro?'} />

    </div>
  );
};

export default Safe;









