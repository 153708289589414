import React from "react";
import { PRODUCTS_CREDITOS } from "views/home/Home.fixture";
import SurgirCardRedirect from "../SurgirCardRedirect/SurgirCardRedirect";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SurgirButton from "../SurgirButton/SurgirButton";
import './SurgirCardsProductCredit.scss'

const ThreeColumnGrid = ({ children }: any) => {
  return (
    <div className="cards-product">
      <div className='grid-container-tabs'>
        {children}
      </div>
    </div>

  );
};

const SurgirCardsProductCredit: React.FC<any> = ({description}) => {

  const navigate = useNavigate();
  const requestCredit = () => {
    navigate("/contactanos");
    window.scrollTo(0, 0);
  };
  
  return (
    <div className="section-protect-cards">
      <Container style={{ textAlign: "center" }}>
        <div className="section-protect-cards--title">
          Protege tu futuro y el de tus seres queridos
        </div>

        <div className="section-protect-cards--description">
          {description}
        </div>

        <div className="section-protect__button">
          <SurgirButton
            onClick={requestCredit}
            variant="danger"
            radius="24px"
            width={"183px"}
            height={"48px"}
            marginBottom={"0px"}
            marginTop={"0px"}
            fontFamily={"'Str-Microtext-Bold', sans-serif"}

          >
            Solicita tu crédito
          </SurgirButton>
        </div>

        <ThreeColumnGrid>
          {
            PRODUCTS_CREDITOS.map((product) => {
              return (
                <SurgirCardRedirect
                  
                  key={product.title}
                  image={product.hero}
                  title={product.title}
                  description={product.description}
                  alt={product.alt}
                  link={product.href}
                />
              )
            })
          }
        </ThreeColumnGrid>

      </Container>


    </div>

  )
};

export default SurgirCardsProductCredit;